import { ServiceProps } from "../features/services.feature";
import Gst from "../../assets/gst.png";
import Accounting from "../../assets/accounting.png";
import Kirana from "../../assets/kirana.png";
import Pharma from "../../assets/pharma.png";
import Pharma2 from "../../assets/Pharma1.png";
import Kirana2 from "../../assets/kirana-2.png";
import garment from "../../assets/Garment.png";
import fotwear from "../../assets/fotwear.png";
import Ayurvedic from "../../assets/Ayurvedic.png";
import book from "../../assets/book.png";
export const Services: ServiceProps[] = [
  {
    id: 1,
    category: "manufacturing",
    path: "1",
    image: Gst,
    title: "GST Billing Software ",
    desc: "Empower Your Business with GST Software: Leading the Way in GST Solutions ",
    features: [
      {
        desc: "Generate GST-compliant invoices effortlessly. Track invoice payments follow-up, and expedite payment processes.Improve billing speed by 40% with shortcuts and search options like Barcode",
        title: "GST Invoices & Billing",
      },
      {
        desc: "Create GST invoices and push transactions into the GSTN portal seamlessly.  File GSTR 1, GSTR 2, GSTR 3B, GSTR 4, GSTR 9 directly from the software. ",
        title: "GST Reports & Return Filing",
      },
      {
        desc: "Easily reconcile GSTR 2A without frequent logins to the GST portal. Download GSTR-2A auto-populated purchase bills for efficient matching in Marg GST software.",
        title: "GSTR 2A Reconciliation",
      },
      {
        desc: "Automatically club together input and output tax for a particular month. Pass a general entry at the end of the month, essential for GST Payable",
        title: "Tax Clubbing",
      },
      {
        desc: "No manual data entry required; import and export data effortlessly",
        title: "Accounting",
      },
    ],
    keyHigh: [
      {
        desc: "Run internal audits effortlessly to guarantee 100% error-free reports, providing a solid foundation for your financial processes.",
        title: "Internal Audit Assurance",
      },
      {
        desc: "Save time and enhance efficiency by managing all your GSTINs in one centralized location, simplifying your business operations.",
        title: "Centralized GSTIN Management",
      },
      {
        desc: "Easily file GSTR 1, GSTR 2, GSTR 3B, GSTR 4, and GSTR 9 directly from our software, ensuring accuracy and adherence to regulatory requirements.",
        title: "Comprehensive Filing Capabilities",
      },
      {
        desc: "Join a community of businesses benefiting from our software, recognized with prestigious awards for excellence",
        title: "Award-Winning Software",
      },
    ],
  },
  {
    id: 2,
    path: "2",
    category: "retail",
    image: Pharma,
    title: "Pharmacy Software",
    desc: "Revolutionize Your Pharmacy Operations with Advanced Pharmacy Software Minimize Expiry Loss and Streamline  ",
    keyHigh: [
      {
        desc: " Simplify billing processes with intuitive software, eliminating manual data entry for more efficient operations.",
        title: "Effortless Invoicing",
      },
      {
        desc: "Place online orders seamlessly by comparing stock and schemes from various distributors across India. ",
        title: "ERP-to-ERP Online Ordering",
      },
      {
        desc: "Easily reconcile GSTR 2A without frequent logins to the GST portal. Download GSTR-2A auto-populated purchase bills for efficient matching in Marg GST software.",
        title: "GSTR 2A Reconciliation",
      },
      {
        desc: "Streamline the ordering process by specifying required quantities during billing, triggering automated alerts when items are available",
        title: "Promise Order Feature",
      },
    ],
    features: [
      {
        desc: "Run internal audits effortlessly to guarantee 100% error-free reports, providing a solid foundation for your financial processes.",
        title: "Stock Management",
      },
      {
        desc: "Prevent losses due to expired products by proactively tracking and returning them to suppliers before expiry. ",
        title: "Expiry Management",
      },
      {
        desc: "Accept both paper and e-prescriptions to expand your customer base.",
        title: "Prescription Handling",
      },
      {
        desc: "Organize stock rack-wise for easy identification during billing and generate informative rack-wise stock reports.",
        title: "Rack Management",
      },
      {
        desc: "Receive timely notifications and alerts based on customer history for improved customer service. ",
        title: "Alert Management",
      },
      {
        desc: "Save time and enhance accuracy by importing bills from Excel, CSV, or PDF formats",
        title: "Online Purchase Import",
      },
      {
        desc: "Identify fast, slow, and non-moving products, set re-order points, and generate re-orders based on various criteria",
        title: "Re-Order Management",
      },
      {
        desc: "Boost sales by suggesting substitute medicines with similar salt composition and strength.  Integrated Features for Seamless Operations ",
        title: "Substitute Availability",
      },
      {
        desc: "Access comprehensive information on medicines to enhance sales and ordering processes. ",
        title: "Free Drug Helpline",
      },
      {
        desc: "Place real-time online orders with nearby suppliers using the dedicated app. ",
        title: "24x7 Ordering",
      },
      {
        desc: "Manage transactions efficiently with online banking integration, including auto-bank reconciliation with numerous banks",
        title: "Connected Banking",
      },
      {
        desc: "Optimize shipping costs and ensure timely deliveries with reliable delivery partners",
        title: "Delivery & Shipping",
      },
    ],
  },
  {
    id: 3,
    path: "3",
    category: "distribution",
    image: Pharma2,
    title: "Pharma Software",
    desc: "Unlock Seamless Pharmacy Management with Swastik Pharmacy Software ",
    features: [
      {
        title: "Stock Management",
        desc: "Track, set min/max levels, and keep inventory up-to-date.",
      },
      {
        title: "Expiry Management",
        desc: " Avoid losses due to expired products",
      },
      {
        title: "Prescription Management",
        desc: " Accept paper and e-prescriptions for broader customer reach",
      },
      {
        title: "Rack Management",
        desc: "Organize stock rack-wise for efficient billing",
      },
      {
        title: "Alert Management",
        desc: "Receive notifications and alerts based on customer history",
      },
      {
        title: "Online Purchase Import",
        desc: "Import bills from various formats for time-saving accuracy",
      },
    ],
    keyHigh: [
      {
        desc: "Streamline your ordering process by placing online orders seamlessly from your ERP to distributors across India.",
        title: "ERP-to-ERP Online Order",
      },
      {
        desc: "Effortlessly manage patient needs during the billing process, and automatically inform them when the prescribed items become available.",
        title: "Promise Order",
      },
      {
        desc: "Eliminate manual data entry with our 'All Scan App,' simplifying billing processes by scanning batch numbers directly into the system.",
        title: "Data Entry Free Software",
      },
    ],
  },
  {
    id: 4,
    path: "4",
    category: "retail",
    desc: "Elevate Your Financial Management Experience",
    title: "AccountsPro Software",
    image: Accounting,
    features: [
      {
        title: "Smart Bookkeeping",
        desc: "Effortlessly manage your books with our intelligent bookkeeping features. From recording transactions to generating financial statements, AccountsPro Software streamlines the entire process",
      },
      {
        title: "In-depth Analytics",
        desc: "Unlock the power of data with our advanced analytics tools. Gain valuable insights into your financial performance, helping you make strategic decisions that drive your business forward",
      },
      {
        title: "GST Compliance",
        desc: " Stay compliant with the latest GST regulations. Our software simplifies GST filing and ensures that your business meets all the necessary tax requirements.",
      },
      {
        title: "Multi-user Accessibility",
        desc: " Collaborate seamlessly with your team. AccountsPro Software allows multiple users to access and contribute to your financial data, promoting collaboration and efficiency",
      },
      {
        title: "Secure and Reliable",
        desc: "Your financial data is precious, and we treat it as such. AccountsPro Software prioritizes the security and reliability of your information, providing you with peace of mind as you manage your finances",
      },
    ],
    keyHigh: [
      {
        desc: "Experience a comprehensive suite of financial tools that caters to various industries, ensuring that your business has the right resources for robust financial management. From bookkeeping to advanced analytics, we've got you covered",
        title: "Comprehensive Financial Solutions",
      },
      {
        desc: "AccountsPro Software is designed with efficiency in mind. Our user-friendly interface and intuitive features empower you to manage your accounts effortlessly, allowing you to focus on what matters most - growing your business",
        title: "Efficiency at Its Core",
      },
      {
        desc: "Whether you're a small startup or a large enterprise, our software is built to scale with your business. As your financial needs evolve, AccountsPro Software evolves with you, providing a flexible and adaptable solution",
        title: "Scalability for Your Growth",
      },
      {
        desc: "Make informed decisions with real-time insights into your financial data. Our software ensures that you have access to accurate and up-to-date information, enabling you to navigate the financial landscape with confidence",
        title: "Real-time Insights",
      },
    ],
  },
  {
    id: 5,
    path: "5",
    category: "distribution",
    desc: "Empowering Global Trade with Seamless Solutions",
    title: "Export Billing Software",
    features: [
      {
        title: "Tailored for Global Trade",
        desc: "Our software is specifically crafted to meet the unique needs of businesses engaged in international trade. We understand the nuances of cross-border transactions and provide solutions that cater to those complexities",
      },
      {
        title: "User-Friendly Interface",
        desc: "Experience a user-friendly interface that makes the export billing process intuitive and accessible. Whether you're a seasoned exporter or new to international trade, our software ensures a smooth experience",
      },
      {
        title: "Scalable for Your Growth",
        desc: "As your export business expands, our Export Billing Software scales with you. From small enterprises to large corporations, our software adapts to your evolving needs, providing a scalable solution for global growth",
      },
      {
        title: "Real-time Updates",
        desc: "Stay informed with real-time updates on your export transactions. Our software keeps you in the loop, providing visibility into the status of invoices, payments, and other critical aspects of your global trade operations",
      },
    ],
    keyHigh: [
      {
        title: "Global Compliance",
        desc: "Navigate the intricate landscape of international trade regulations effortlessly. Our Export Billing Software ensures that your billing processes align with global compliance standards, reducing the risk of errors and delays",
      },
      {
        desc: "Conduct transactions in any currency with confidence. Our software supports multiple currencies, providing you with the flexibility to operate in diverse international markets without the hassle of currency conversions",
        title: "Multi-Currency Support",
      },
      {
        desc: "Create professional and detailed invoices tailored for international transactions. Our Export Billing Software simplifies the invoicing process, ensuring clarity and transparency in your financial documentation",
        title: "Streamlined Invoicing",
      },
      {
        desc: "Gain insights into your export transactions with comprehensive reporting tools. Monitor sales, track payments, and generate detailed reports to facilitate informed decision-making in your global business operations",
        title: "Comprehensive Reporting",
      },
      {
        desc: "Handle export documentation with ease. Our software assists in generating essential export documents, reducing paperwork and ensuring accuracy in compliance with international trade regulations",
        title: "Efficient Documentation",
      },
    ],
  },
  {
    id: 6,
    path: "6",
    category: "distribution",
    desc: "Revolutionizing Your Business Finances",
    title: "Margerp Billing Software",
    features: [
      {
        title: "Industry-Specific Solutions",
        desc: "MargERP understands that different industries have unique requirements. Our software offers industry-specific solutions, ensuring that your business gets the tools it needs for success",
      },
      {
        title: "Scalability for Growth",
        desc: "As your business expands, MargERP grows with you. Our software is scalable, adapting to the evolving needs of your business, whether you're a small startup or a large enterprise",
      },
      {
        title: "Real-Time Updates",
        desc: "Stay informed with real-time updates on your financial data. MargERP keeps you in the loop, providing visibility into crucial aspects of your business, allowing you to make informed decisions",
      },
      {
        title: "Dedicated Support",
        desc: "Experience unmatched customer support with MargERP. Our team is dedicated to ensuring that you get the assistance you need, making your experience with our software smooth and hassle-free",
      },
    ],
    keyHigh: [
      {
        desc: "Experience intelligent and efficient invoicing with MargERP. From generating invoices to managing billing cycles, our software simplifies the entire process, ensuring accuracy and timeliness",
        title: "Smart Invoicing",
      },
      {
        desc: "Stay ahead of tax regulations with seamless GST compliance. MargERP Billing Software is equipped to handle all aspects of GST, from billing to return filing, ensuring that your business meets all tax requirements",
        title: "GST Compliance",
      },
      {
        desc: "Efficiently manage your inventory with MargERP. Track stock levels, set reorder points, and streamline your supply chain for enhanced control over your business operations",
        title: "Inventory Management",
      },
      {
        desc: "Gain valuable insights into your business performance with customizable reports. MargERP allows you to generate detailed reports tailored to your specific business needs, facilitating informed decision-making",
        title: "Customizable Reports",
      },
      {
        desc: "Navigate through MargERP effortlessly with our user-friendly interface. Whether you're a seasoned professional or new to business management software, our intuitive design ensures a smooth and accessible experience",
        title: "User-Friendly Interface",
      },
    ],
  },
  {
    category: "distribution",
    desc: "Safeguarding Your Digital World with Precision",
    features: [
      {
        title: "Proven Track Record",
        desc: "Quick Heal has a legacy of excellence in the cybersecurity domain. With millions of satisfied users globally, our antivirus solution is trusted for its efficiency, reliability, and unwavering commitment to user security",
      },
      {
        title: "Intuitive User Interface",
        desc: "Navigate through Quick Heal's user-friendly interface effortlessly. Our antivirus software is designed with simplicity in mind, ensuring that users of all levels can utilize its powerful features with ease",
      },
      {
        title: "Regular Updates and Support",
        desc: "Quick Heal is committed to your security. Benefit from regular updates and dedicated customer support to address any queries or concerns. Your digital safety is our top priority. ",
      },
      {
        title: "Multi-Platform Protection:",
        desc: "Secure all your devices with Quick Heal's multi-platform protection. Whether you're using Windows, Mac, Android, or iOS, our antivirus solution extends its protective shield across various operating systems",
      },
    ],
    id: 7,
    path: "7",
    keyHigh: [
      {
        desc: "Quick Heal Antivirus employs state-of-the-art threat detection mechanisms to safeguard your system against a spectrum of evolving cyber threats. From viruses and malware to ransomware and phishing attacks, we've got you covered",
        title: "Advanced Threat Protection",
      },
      {
        desc: "Stay ahead of emerging threats with real-time security updates. Quick Heal's vigilant system ensures that your antivirus protection is always equipped to combat the latest vulnerabilities and cyber risks",
        title: "Real-Time Security Updates",
      },
      {
        desc: "Protect your valuable data from ransomware attacks with Quick Heal's robust ransomware protection. Safeguard your files and documents against unauthorized encryption, ensuring peace of mind in the face of digital extortion",
        title: "Ransomware Protection",
      },
      {
        desc: "Navigate the digital landscape securely with Quick Heal's web security features. Enjoy safe browsing and prevent malicious websites from compromising your online activities",
        title: "Web Security",
      },
      {
        desc: " Defend your inbox against phishing attempts, malicious attachments, and spam with Quick Heal's comprehensive email security. Keep your communication channels secure and shielded from cyber threats",
        title: "Email Security",
      },
    ],
    title: "Quick Heal Antivirus",
  },
  {
    id: 8,
    path: "8",
    desc: "",
    features: [
      {
        desc: "Our software is designed with a deep understanding of the unique challenges faced by FMCG businesses. From short shelf-life products to high-demand fluctuations, our solution is tailored to the intricacies of the industry.",
        title: "Tailored for FMCG",
      },
      {
        title: "Scalable for Growth",
        desc: "As your FMCG business expands, our software grows with you. Whether you're a small-scale manufacturer or a large-scale distributor, our scalable solution adapts to your evolving needs, providing a robust foundation for growth",
      },
      {
        title: "User-Friendly Interface",
        desc: "Navigate through our FMCG Software effortlessly. Our user-friendly interface ensures that users at all levels can maximize the software's potential, enhancing overall operational efficiency",
      },
      {
        title: "Real-Time Collaboration",
        desc: "Facilitate real-time collaboration between different stakeholders in your supply chain. Enhance communication and coordination, ensuring that everyone involved is on the same page for efficient operations",
      },
    ],
    keyHigh: [
      {
        desc: "Effortlessly manage your fast-paced inventory with precision. Our FMCG Software enables real-time tracking, optimizing stock levels, and ensuring that your products are always readily available to meet consumer demand",
        title: "Inventory Management",
      },
      {
        desc: "Streamline your order processing and distribution channels for faster, error-free transactions. Our software facilitates seamless coordination between suppliers, distributors, and retailers, ensuring a smooth flow of goods",
        title: "Order Processing and Distribution",
      },
      {
        desc: "Gain valuable insights into your sales and distribution networks. Our analytics tools provide a comprehensive overview, allowing you to make data-driven decisions that enhance your market strategy and boost profitability",
        title: "Sales and Distribution Analytics:",
      },
      {
        desc: "Stay ahead in the competitive FMCG landscape with built-in promotional and marketing tools. Plan and execute effective campaigns, track their impact, and adapt your strategies for maximum consumer engagement",
        title: "Promotional and Marketing Tools",
      },
      {
        desc: "Navigate the complex landscape of FMCG regulations effortlessly. Our software is equipped to handle compliance requirements, ensuring that your business operates within the legal framework of the FMCG industry",
        title: "Compliance and Regulatory Support",
      },
    ],
    title: "FMCG Software",
    category: "distribution",
  },
  {
    id: 9,
    desc: "Empowering Your Business Mobility",
    path: "9",
    category: "distribution",
    features: [
      {
        title: "Business Agility",
        desc: "Our Mobile Billing Software is tailored for businesses that demand agility. With the ability to manage billing tasks on the go, your business gains a competitive edge by responding swiftly to customer needs and market trends",
      },
      {
        title: "Time and Cost Efficiency",
        desc: "Save time and reduce costs associated with traditional billing processes. With our mobile solution, you eliminate the need for manual paperwork and gain efficiency in handling billing tasks with just a few taps on your mobile device",
      },
      {
        title: "Integration Capabilities",
        desc: "Integrate our Mobile Billing Software seamlessly with your existing business systems. Whether it's accounting software, CRM, or other essential tools, our solution ensures that your mobile billing processes align with your broader business operations",
      },
      {
        title: "Customizable Templates:",
        desc: "Tailor your invoices with customizable templates that reflect your brand identity. Our software allows you to create professional and branded invoices, enhancing your business's image and credibility",
      },
    ],
    keyHigh: [
      {
        desc: "Unlock the freedom to generate invoices and manage billing tasks from anywhere, at any time. Our Mobile Billing Software ensures that you have the flexibility to conduct business on the move, whether you're in the field, at a client's location, or simply away from your desk.",
        title: "Anytime, Anywhere Billing",
      },
      {
        desc: "Stay informed with real-time updates on your billing activities. Receive instant notifications, track payment statuses, and access crucial financial information directly from your mobile device, providing you with the agility to make informed decisions",
        title: "Real-Time Updates",
      },
      {
        desc: "Prioritize the security of your mobile transactions. Our software employs robust encryption and security measures to safeguard your financial data, ensuring that every billing transaction is conducted securely and without compromise",
        title: "Secure Transactions",
      },
      {
        desc: "Navigate through our Mobile Billing Software effortlessly with an intuitive user interface. Whether you're a seasoned professional or new to mobile billing solutions, our design ensures a seamless and user-friendly experience.",
        title: "User-Friendly Interface",
      },
    ],
    title: "Mobile Billing Software",
  },
  {
    id: 10,
    path: "10",
    category: "retail",
    title: "Garment Billing Software",
    image: garment,
    desc: "Elevating Your Fashion Business",
    features: [
      {
        title: "Style-Driven Billing",
        desc: "Experience style-driven billing that aligns with the uniqueness of the fashion industry. Our software is equipped to handle the intricacies of garment billing, accommodating diverse styles, sizes, and pricing structures effortlessly",
      },
      {
        title: "Efficient Inventory Management",
        desc: "Optimize your inventory management with features tailored for the garment industry. From tracking stock levels for different sizes and colors to managing seasonal collections, our software ensures that your inventory is always in tune with market demands",
      },
      {
        title: "Seasonal Collections and Discounts",
        desc: "Facilitate seasonal collections and discounts seamlessly. Our Garment Billing Software allows you to implement and manage seasonal promotions, discounts, and special offers, enhancing customer engagement and boosting sales",
      },
      {
        title: "Barcode Scanning",
        desc: " Streamline your billing processes with barcode scanning capabilities. Our software supports barcode technology, reducing manual errors, improving accuracy, and expediting the billing of garments with ease",
      },
    ],
    keyHigh: [
      {
        desc: "Our software is crafted with a deep understanding of the fashion and apparel industry. We provide fashion-centric solutions that address the unique challenges faced by garment businesses, ensuring a perfect fit for your billing needs.",
        title: "Fashion-Centric Solutions",
      },
      {
        desc: "Engage your customers with personalized billing experiences. From loyalty programs to personalized discounts, our software allows you to build lasting relationships with your clientele and keep them coming back for the latest fashion trends. ",
        title: "Enhanced Customer Engagement",
      },
      {
        desc: "If your fashion business spans multiple locations, our Garment Billing Software is up to the task. Manage billing processes seamlessly across various stores, ensuring consistency and accuracy in your fashion retail operations",
        title: "Multi-Location Support",
      },
      {
        desc: "Make data-driven decisions with trend analysis and comprehensive reporting tools. Our software provides insights into the performance of different garments, helping you make informed decisions on inventory, pricing, and marketing strategies",
        title: "Trend Analysis and Reporting",
      },
    ],
  },
  {
    id: 11,
    path: "11",
    category: "retail",
    title: "Footwear Billing Software ",
    desc: "Stride Ahead in Business Efficiency ",
    image: fotwear,
    features: [
      {
        title: "Size and Style Dynamics",
        desc: "Our Footwear Billing Software is designed to handle the size and style dynamics inherent in the footwear industry. Efficiently manage diverse sizes, styles, and variations, ensuring accurate billing for every pair",
      },
      {
        title: "Inventory Harmony",
        desc: "Achieve harmony in your inventory management. From tracking stock levels for different shoe sizes to managing seasonal collections, our software ensures that your footwear inventory aligns seamlessly with market demands",
      },
      {
        title: "Barcode Scanning Precision",
        desc: "Streamline billing processes with precision using barcode scanning capabilities. Our software supports barcode technology, reducing errors, improving accuracy, and facilitating the swift billing of footwear items",
      },
      {
        title: "Seasonal Collections and Promotions",
        desc: "Effortlessly manage seasonal collections and promotional events. Our Footwear Billing Software empowers you to implement and oversee seasonal discounts, promotions, and special offers, enhancing customer engagement and boosting sales",
      },
    ],
    keyHigh: [
      {
        desc: "Our software is developed with a profound understanding of the footwear retail sector. We provide industry-centric solutions that address the unique challenges faced by footwear businesses, ensuring a tailored fit for your billing needs",
        title: "Industry-Centric Solutions",
      },
      {
        desc: "Elevate your customer experience with personalized billing interactions. From loyalty programs to special discounts, our software enables you to build lasting relationships with your customers, fostering loyalty and repeat business",
        title: "Enhanced Customer Experience",
      },
      {
        desc: "If your footwear business spans multiple locations, our Footwear Billing Software is equipped to handle it seamlessly. Manage billing processes consistently across various stores, ensuring uniformity and accuracy in your footwear retail operations",
        title: "Multi-Store Support",
      },
      {
        desc: "Make informed decisions with trend analysis and robust reporting tools. Our software provides insights into the performance of different footwear items, assisting you in making strategic decisions on inventory, pricing, and marketing.",
        title: "Trend Analysis and Reporting",
      },
    ],
  },
  {
    id: 12,
    path: "12",
    category: "retail",
    image: Kirana2,
    title: "kirana Grocery Billing Software",
    desc: "Redefining Convenience in Retail",
    features: [
      {
        title: "Itemized Billing for Diverse Products",
        desc: "Efficiently handle a diverse range of grocery items with our itemized billing feature. From fresh produce to packaged goods, our software ensures accurate and seamless billing for every product in your Kirana store",
      },
      {
        title: "Inventory Optimization",
        desc: "Optimize your inventory management effortlessly. Our software allows you to track stock levels, manage shelf-life for perishable items, and maintain consistency in inventory, ensuring that your Kirana store is always well-stocked and ready to serve customers",
      },
      {
        title: "Barcode Scanning for Quick Checkout",
        desc: "Speed up the checkout process with barcode scanning capabilities. Our Kirana Grocery Billing Software supports barcode technology, reducing waiting times, eliminating errors, and enhancing the overall shopping experience for your customers",
      },
      {
        title: "Seasonal Promotions and Discounts",
        desc: "Effortlessly manage seasonal promotions and discounts to attract and retain customers. Our software empowers you to implement and oversee various promotional activities, enhancing customer engagement and boosting sales during peak seasons",
      },
    ],
    keyHigh: [
      {
        desc: "Our software is crafted with a deep understanding of the unique dynamics of Kirana grocery stores. We provide neighborhood-centric solutions that address the specific requirements of small-scale retail, ensuring a perfect fit for your billing needs",
        title: "Neighborhood-Centric Solutions",
      },
      {
        desc: "Navigate our Kirana Grocery Billing Software with ease. Designed with a user-friendly interface, our software ensures that users at all levels, from store owners to cashiers, can maximize its potential without extensive training",
        title: "User-Friendly Interface",
      },
      {
        desc: "Accommodate diverse customer preferences with multi-payment options. Our software supports various payment methods, including cash, cards, and digital wallets, providing convenience to your customers and streamlining the checkout process",
        title: "Multi-Payment Options",
      },
      {
        desc: "Make informed decisions with analytics tools that offer insights into customer preferences, popular products, and sales trends. Our software provides valuable data to help you optimize your inventory and marketing strategies",
        title: "Analytics for Informed Decision-Making",
      },
    ],
  },
  {
    id: 13,
    image: Kirana,
    category: "retail",
    path: "13",
    title: "Supermarket Software",
    desc: "Transforming Retail Excellence",
    features: [
      {
        title: "Comprehensive Product Catalog",
        desc: "Effortlessly manage a vast range of products with our comprehensive product catalog. Our Supermarket Software allows you to categorize items, track inventory, and ensure accurate and efficient billing for diverse product categories",
      },
      {
        title: "Advanced Inventory Control",
        desc: "Optimize your inventory management with advanced control features. From real-time stock tracking to automated replenishment, our software ensures that your supermarket shelves are well-stocked, minimizing the risk of stockouts or overstocking",
      },
      {
        title: "Quick and Accurate Billing",
        desc: "Enhance the checkout experience with quick and accurate billing processes. Our Supermarket Software supports features such as barcode scanning and multiple payment options, streamlining the billing process and reducing customer wait times",
      },
      {
        title: " Promotions and Loyalty Programs",
        desc: "Attract and retain customers with effective promotional campaigns and loyalty programs. Our software enables you to implement discounts, promotions, and loyalty rewards, enhancing customer engagement and fostering brand loyalty",
      },
    ],
    keyHigh: [
      {
        desc: "Our Supermarket Software is designed to meet the specific needs of modern supermarkets. Whether you operate a single store or a chain of supermarkets, our solution provides the tools necessary for efficient retail management",
        title: "Tailored for Modern Supermarkets",
      },
      {
        desc: "Navigate through our Supermarket Software with ease. The user-friendly interface ensures that your staff can quickly adapt to the system, minimizing training time and maximizing operational efficiency",
        title: "User-Friendly Interface",
      },
      {
        desc: "Make informed decisions with real-time analytics and comprehensive reporting tools. Our software provides insights into sales trends, customer preferences, and inventory turnover, empowering you to make strategic business decisions",
        title: "Real-Time Analytics and Reporting:",
      },
      {
        desc: "Seamlessly integrate our Supermarket Software with other business systems. Whether it's accounting software, CRM, or online platforms, our solution ensures that your retail operations are synchronized for maximum efficiency",
        title: "Integration Capabilities",
      },
    ],
  },
  {
    id: 14,
    image: Kirana2,
    category: "retail",
    path: "14",
    title: "Retail Software",
    desc: "Unleashing the Power of Seamless Retail Management",
    features: [
      {
        title: "Unified Point of Sale (POS)",
        desc: "Transform your checkout experience with our Unified Point of Sale system. Our Retail Software provides a user-friendly interface, supports multiple payment options, and streamlines the billing process, ensuring quick and accurate transactions",
      },
      {
        title: "Advanced Inventory Management:",
        desc: "Take control of your inventory with advanced management features. From real-time stock tracking to automated reordering, our software optimizes inventory levels, minimizing stockouts and overstocking for a more efficient supply chain",
      },
      {
        title: "Customer Relationship Management (CRM)",
        desc: "Enhance customer relationships with our CRM capabilities. Capture and analyze customer data, implement loyalty programs, and personalize marketing efforts to build lasting connections with your clientele",
      },
      {
        title: "Multichannel Integration",
        desc: "Seamlessly integrate your retail operations across various channels. Whether it's brick-and-mortar stores, e-commerce platforms, or mobile applications, our Retail Software ensures a unified and synchronized retail experience",
      },
    ],
    keyHigh: [
      {
        desc: "Our Retail Software is crafted to meet the specific needs of modern retailers. Whether you operate a single-store boutique or a large retail chain, our solution provides the flexibility and scalability required for success",
        title: "Tailored for Modern Retailers",
      },
      {
        desc: "Navigate through our Retail Software with ease. The user-friendly interface ensures that your staff can quickly adapt to the system, reducing training time and enhancing overall operational efficiency",
        title: "User-Friendly Interface",
      },
      {
        desc: " Make data-driven decisions with real-time analytics and comprehensive reporting tools. Our software provides insights into sales trends, inventory turnover, and customer behavior, enabling you to strategize for business growth",
        title: "Real-Time Analytics and Reporting",
      },
      {
        desc: "Our Retail Software prioritizes security and scalability. Whether you're a small business experiencing growth or a large enterprise, our solution adapts to your changing needs while ensuring the security of your business data",
        title: "Secure and Scalable",
      },
    ],
  },
  {
    id: 15,
    path: "15",
    category: "distribution",
    title: "Automobile and Spare parts Software",
    desc: "Driving Efficiency in Automotive Retail",
    features: [
      {
        title: "Extensive Catalog Management",
        desc: "Effortlessly manage a vast array of automobile parts and accessories with our extensive catalog management. From engine components to exterior accessories, our software ensures accurate and efficient handling of diverse inventories",
      },
      {
        title: "Efficient Order Processing",
        desc: "Streamline your order processing with our software. Whether you're dealing with retail customers or managing bulk orders, our solution ensures efficient order fulfillment, reducing turnaround times and enhancing customer satisfaction",
      },
      {
        title: "Comprehensive Vehicle Diagnostics",
        desc: "Offer superior services with comprehensive vehicle diagnostics. Our software enables you to track and manage vehicle service histories, schedule maintenance tasks, and provide personalized recommendations to customers, fostering long-term relationships",
      },
      {
        title: "Inventory Optimization",
        desc: "Optimize your spare parts inventory with advanced features. From managing stock levels to automating reordering processes, our software minimizes the risk of overstocking or stockouts, ensuring that you have the right parts at the right time",
      },
    ],
    keyHigh: [
      {
        desc: "Our software is crafted with a deep understanding of the automotive and spare parts industry. We provide solutions that address the unique challenges faced by businesses in this sector, ensuring a perfect fit for your operational needs. ",
        title: "Automotive-Centric Solutions",
      },
      {
        desc: "Navigate through our Automobile and Spare Parts Software with ease. The user-friendly interface ensures that your staff, whether in sales or service, can quickly adapt to the system, enhancing overall operational efficiency",
        title: "User-Friendly Interface",
      },
      {
        desc: "Make informed decisions with real-time analytics and reporting tools. Our software provides insights into inventory turnover, sales trends, and customer preferences, empowering you to strategize for business growth",
        title: "Real-Time Analytics and Reporting",
      },
      {
        desc: "Seamlessly integrate our software with other business systems. Whether it's accounting software, CRM, or online sales platforms, our solution ensures that your automotive retail operations are synchronized for maximum efficiency.",
        title: "Integration Capabilities",
      },
    ],
  },
  {
    id: 16,
    path: "16",
    category: "retail",
    image: Ayurvedic,
    title: "Ayurvedic and homoeopathic Software",
    desc: "Nurturing Health Through Technological Excellence ",
    features: [
      {
        title: "Patient-Centric Management",
        desc: "Prioritize patient care with our patient-centric management features. Our software allows you to maintain comprehensive patient records, track health histories, and personalize treatment plans for holistic healthcare management",
      },
      {
        title: "Prescription Efficiency",
        desc: "Enhance your prescription processes with our software. Whether you're prescribing Ayurvedic formulations or Homoeopathic remedies, our solution ensures accurate and efficient prescription generation, complete with dosage instructions and follow-up recommendations",
      },
      {
        title: "Inventory Control for Herbal Medicines",
        desc: "Efficiently manage your inventory of herbal medicines and remedies. Our software supports inventory tracking, expiry date management, and automated reorder processes, ensuring that you always have the necessary herbal medicines in stock",
      },
      {
        title: "Appointment Scheduling and Reminders",
        desc: "Streamline your clinic's operations with appointment scheduling and reminders. Our software facilitates efficient scheduling, sends automated reminders to patients, and reduces no-shows, allowing you to maintain a well-organized practice",
      },
    ],
    keyHigh: [
      {
        title: "Healthcare-Centric Solutions",
        desc: "Our software is crafted with a deep understanding of Ayurvedic and Homoeopathic healthcare practices. We provide solutions that address the unique requirements of these traditional healing systems, ensuring a perfect fit for your clinic",
      },
      {
        desc: "Navigate through our Ayurvedic and Homoeopathic Software with ease. The user-friendly interface ensures that your clinic staff can quickly adapt to the system, minimizing training time and maximizing operational efficiency",
        title: "User-Friendly Interface",
      },
      {
        desc: "Seamlessly integrate our software with other healthcare systems. Whether it's electronic health records (EHR), laboratory systems, or billing software, our solution ensures that your clinic's operations are synchronized for maximum efficiency",
        title: "Integration Capabilities",
      },
      {
        desc: "Prioritize the security and privacy of patient data. Our software adheres to data protection standards and healthcare regulations, ensuring compliance with industry requirements and fostering trust with patients",
        title: "Data Security and Compliance",
      },
    ],
  },
  {
    id: 17,
    path: "17",
    category: "distribution",
    title: "Agriculture krushi software",
    desc: "Cultivating Success Through Innovation",
    features: [
      {
        title: "Crop Planning and Management",
        desc: "Optimize your crop planning with our software. Whether it's selecting the right crops for your region, planning planting schedules, or managing crop rotations, our solution ensures that your agricultural activities are organized and efficient",
      },
      {
        title: "Resource Optimization",
        desc: "Maximize resource efficiency with our Agriculture Krushi Software. Manage water usage, fertilizer application, and pest control with precision, ensuring that your resources are utilized effectively for optimal crop growth",
      },
      {
        title: "Weather Data Integration",
        desc: "Stay ahead of weather patterns with integrated weather data. Our software allows you to access real-time weather information, enabling you to make informed decisions regarding planting, harvesting, and other critical farming activities",
      },
      {
        title: "Crop Health Monitoring",
        desc: "Monitor the health of your crops with advanced tracking features. From identifying potential diseases to assessing overall crop vitality, our software empowers you to take proactive measures for crop protection and yield optimization",
      },
    ],
    keyHigh: [
      {
        desc: "Our software is crafted with a deep understanding of the agriculture sector. We provide solutions that address the unique challenges faced by farmers, ensuring a perfect fit for your farm management needs",
        title: "Farming-Centric Solutions",
      },
      {
        desc: "Navigate through our Agriculture Krushi Software with ease. The user-friendly interface ensures that farmers, regardless of technological expertise, can quickly adapt to the system, minimizing training time and maximizing operational efficiency",
        title: "User-Friendly Interface",
      },
      {
        desc: "Access your farm data on the go with mobile accessibility. Our software is designed to be compatible with mobile devices, allowing you to manage your farm operations from the field, making timely decisions and adjustments",
        title: "Mobile Accessibility",
      },
      {
        desc: "Make informed decisions with data-driven insights. Our software provides analytics on crop performance, resource utilization, and other key metrics, enabling you to strategize for improved agricultural outcomes",
        title: "Data-driven Decision Making",
      },
    ],
  },
  {
    id: 18,
    path: "18",
    category: "manufacturing",
    title: "Customize ERP Solution Software",
    desc: "Tailored Excellence for Your Business",
    features: [
      {
        title: "Tailored to Your Business",
        desc: "Our software is crafted to meet the specific needs and workflows of your industry. We understand that each business is unique, and our solution is designed to be a perfect fit for your operational requirements",
      },
      {
        title: "Strategic Decision-Making",
        desc: "Make informed decisions with real-time data insights. Our software provides analytics and reporting tools that offer valuable insights into your business performance, enabling strategic decision-making for sustainable growth",
      },
      {
        title: "Cost and Time Efficiency",
        desc: "Streamline your business processes for cost and time efficiency. Our Customized ERP Solution Software is designed to automate routine tasks, minimize manual efforts, and optimize resource utilization, ultimately reducing operational costs",
      },
      {
        title: "Dedicated Support and Training",
        desc: "Benefit from dedicated support and training resources. Our team is committed to ensuring that you make the most out of our software. From onboarding to ongoing support, we're here to help you leverage the full potential of your ERP solution",
      },
    ],
    keyHigh: [
      {
        desc: "Our Customized ERP Solution Software includes industry-specific modules designed to address the unique challenges of your business. From manufacturing and finance to supply chain and customer relationship management, our solution covers every aspect of your operations",
        title: "Industry-Specific Modules",
      },
      {
        desc: "Adapt and grow with our scalable and flexible software. Whether you're a small startup or a large enterprise, our solution can be customized to accommodate your current needs while providing the flexibility to scale as your business expands",
        title: "Scalability and Flexibility",
      },
      {
        desc: "Seamlessly integrate our software with your existing business systems. Our solution ensures that your ERP is synchronized with other tools and platforms, enabling a smooth flow of data across various departments for enhanced efficiency",
        title: "Integration Capabilities",
      },
      {
        desc: "Navigate through our Customized ERP Solution Software with ease. The user-friendly interface ensures that your team can quickly adapt to the system, reducing training time and optimizing the overall user experience",
        title: "User-Friendly Interface",
      },
    ],
  },
  {
    id: 19,
    category: "manufacturing",
    path: "19",
    title: "Custom Software ",
    desc: "Unleashing Possibilities, Tailoring Success",
    features: [
      {
        title:
          "Our software is not one-size-fits-all. We understand that each business is unique, and our solutions are designed to be a perfect fit for your specific industry, processes, and objectives",
        desc: "Tailored to Your Business",
      },
      {
        title: "Enhanced Efficiency",
        desc: "Streamline your operations for enhanced efficiency. Our custom software solutions are built to automate repetitive tasks, minimize manual efforts, and optimize resource utilization, ultimately boosting productivity",
      },
      {
        title: "Strategic Decision-Making",
        desc: "Make informed decisions with real-time insights. Our solutions provide advanced analytics and reporting tools, offering valuable data insights into your business performance for strategic decision-making",
      },
      {
        title: "Dedicated Support and Maintenance",
        desc: "Experience peace of mind with dedicated support and maintenance. Our team is committed to ensuring the ongoing success of your custom software, providing support, updates, and continuous improvements as your business evolves",
      },
    ],
    keyHigh: [
      {
        desc: "Our Custom Software Solutions are designed with your business in mind. We tailor the functionality to precisely fit your unique requirements, ensuring that the software aligns seamlessly with your workflows and processes",
        title: "Tailored Functionality",
      },
      {
        desc: "Adapt and grow effortlessly with our scalable and flexible solutions. Whether you're a startup or a large enterprise, our custom software is built to scale alongside your business, providing the flexibility needed for future growth",
        title: " Scalability and Flexibility",
      },
      {
        desc: "Seamlessly integrate our custom software with your existing systems. We ensure that your software works cohesively with other tools and platforms, allowing for smooth data flow across various departments for enhanced efficiency",
        title: "Integration Capabilities",
      },
      {
        desc: "Navigate through our custom software solutions with ease. The user-centric design ensures that your team can quickly adapt to the system, reducing training time and optimizing overall user experience",
        title: "User-Centric Design",
      },
    ],
  },
  {
    id: 20,
    path: "20",
    category: "distribution",
    title: "Plywood software",
    desc: "Elevating Plywood Manufacturing with Technological Precision",
    features: [
      {
        title: "",
        desc: "",
      },
    ],
    keyHigh: [
      {
        desc: "Optimize your production planning with our software. Whether it's managing cutting patterns, ensuring quality control, or streamlining manufacturing schedules, our solution ensures precision and efficiency in every step of plywood production",
        title: "Production Planning and Optimization",
      },
      {
        desc: "Efficiently manage your raw materials and inventory. Our Plywood Software supports real-time tracking of material usage, minimizes wastage, and ensures that you have the right materials at the right time, streamlining the manufacturing process",
        title: "Inventory Control and Material Management",
      },
      {
        desc: "Ensure quality and compliance with our software. From monitoring production standards to adhering to industry regulations, our solution supports quality assurance measures that guarantee the production of high-quality plywood products",
        title: "Quality Assurance and Compliance",
      },
      {
        desc: " Track production costs and make data-driven decisions. Our Plywood Software provides analytics tools that offer insights into manufacturing costs, resource utilization, and production efficiency, empowering strategic decision-making",
        title: "Cost Tracking and Analytics",
      },
    ],
  },
  {
    id: 21,
    path: "21",
    category: "manufacturing",
    title: "tiles and Glass software",
    desc: "Precision in Crafting Elegance with Technological Finesse",
    features: [
      {
        title: "Industry-Centric Solutions",
        desc: "Our software is crafted with a deep understanding of the tiles and glass manufacturing industry. We provide solutions that address the unique challenges faced by manufacturers, ensuring a perfect fit for your operational needs",
      },
      {
        title: "User-Friendly Interface",
        desc: "Navigate through our Tiles and Glass Software with ease. The user-friendly interface ensures that your team can quickly adapt to the system, minimizing training time and optimizing the overall user experience",
      },
      {
        title: "Precision in Craftsmanship",
        desc: "Achieve precision in every piece crafted. Our Tiles and Glass Software is designed to minimize errors, ensure accurate cutting patterns, and enhance overall manufacturing precision, resulting in tiles and glass products that exude elegance",
      },
      {
        title: "Scalability for Growth",
        desc: "Grow your manufacturing capabilities with our scalable software. Whether you're a small tiles and glass workshop or part of a larger manufacturing enterprise, our solution adapts to your changing needs while ensuring the scalability required for business growth",
      },
    ],
    keyHigh: [
      {
        desc: "Optimize your production planning with our software. Whether it's managing cutting patterns for tiles or ensuring precision in glass shaping, our solution ensures efficient and accurate production processes, streamlining manufacturing schedules for maximum output",
        title: "Production Planning and Optimization",
      },
      {
        desc: "Efficiently manage your raw materials and inventory. Our Tiles and Glass Software supports real-time tracking of material usage, minimizing wastage, and ensuring that you have the right materials at the right time, facilitating a seamless manufacturing process",
        title: "Inventory Control and Material Management",
      },
      {
        desc: "Ensure impeccable quality and compliance with our software. From monitoring production standards to adhering to industry regulations, our solution supports quality assurance measures that guarantee the production of high-quality tiles and glass products",
        title: "Quality Assurance and Compliance",
      },
      {
        desc: "Achieve design precision and flexibility with our software. Whether you're crafting intricate tile patterns or precision-cut glass designs, our solution empowers you to customize and create with unparalleled accuracy, meeting the diverse needs of the market",
        title: "Customization and Design Precision",
      },
    ],
  },
  {
    id: 22,
    category: "retail",
    path: "22",
    image: book,
    title: "Books and Stationary software",
    desc: "Elevating Organization and Efficiency in the World of Reading and Writing ",
    features: [
      {
        title: "Industry-Centric Solutions",
        desc: "Our software is crafted with a deep understanding of the books and stationery industry. We provide solutions that address the unique challenges faced by retailers, ensuring a perfect fit for your operational needs",
      },
      {
        title: "User-Friendly Interface",
        desc: "Navigate through our Books and Stationery Software with ease. The user-friendly interface ensures that your staff can quickly adapt to the system, reducing training time and optimizing the overall user experience",
      },
      {
        title: "Sales and Promotion Management",
        desc: "Boost your sales with effective promotion management. Our software allows you to run promotions, discounts, and loyalty programs, helping you attract customers and increase revenue",
      },
      {
        title: "Scalability for Growth",
        desc: "Grow your business with our scalable software. Whether you operate a small independent bookstore or part of a larger retail chain, our solution adapts to your changing needs while ensuring the scalability required for business growth.",
      },
    ],
    keyHigh: [
      {
        desc: "Efficiently manage your book and stationery inventory with our software. From tracking book titles to organizing stationery items, our solution ensures real-time visibility and control over your stock, minimizing overstocking or stockouts",
        title: "Inventory Management",
      },
      {
        desc: "Transform your sales processes with our integrated POS system. Our software provides a user-friendly interface for quick and accurate transactions, supporting multiple payment options and enhancing the overall customer experience",
        title: "Point of Sale (POS) System",
      },
      {
        desc: "Streamline your ordering processes with our software. Manage supplier information, automate reordering of popular items, and optimize your supply chain for timely and efficient stock replenishment",
        title: "Order and Supplier Management",
      },
      {
        desc: "Build lasting connections with your customers using our CRM features. Capture and analyze customer data, implement loyalty programs, and personalize marketing efforts to enhance customer engagement and loyalty",
        title: "Customer Relationship Management (CRM)",
      },
    ],
  },
];
