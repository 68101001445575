import React, { FC, Fragment } from "react";
import Card from "../components/UI/Card";
import "swiper/css";
import Helmet from "react-helmet";
import serBanner from "../assets/hyb2-1536x984.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareRss, faUserShield, faSnowflake } from "@fortawesome/free-solid-svg-icons";

import Mission1 from "../assets/hyb4.jpg";
import Testimonial from "../components/testimonial/Testimonial";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaArchive, FaArrowLeft, FaArrowRight, FaCheckSquare, FaQrcode, FaWifi } from "react-icons/fa";
import John from "../assets/10-1.jpg";
import John1 from "../assets/11-1.jpg";
import Mike from "../assets/12-1.jpg";
import bnImg from "../assets/hyb8.jpg";
import Mission from "../assets/hyb20.jpg";

import retailSoftware from "../assets/RetailSoftware.png";
import distribution_software from "../assets/distribution_software.png";
import manufacturing_software from "../assets/manufacturing_software.png";
import gst_software from "../assets/gst_software.png";
import work_process from "../assets/work_process.png";
import FaqLanding from "../components/faq_landing/FaqLanding";
import { Link } from "react-router-dom";
import { useServiceSlice } from "../redux/features/";

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
     const { services } = useServiceSlice();
     return (
          <Fragment>
               <Helmet>
                    <title>Home</title>
                    <meta name="description" content="home" />
               </Helmet>
               <section>
                    <div className="container-fluid  fadeInUp" style={{ backgroundColor: "#FAFAFA" }}>
                         <div className="container fadeInUp">
                              <div
                                   className="row align-items-center rowPading "
                                   style={{ padding: "20px 0px 0px 0px" }}
                              >
                                   <div className="col-lg-7 col-md-6 col-sm-12 ">
                                        <div>
                                             <h1 className="techHader">
                                                  Empower Your Business <br />
                                                  <span style={{ color: "#4D52D9" }}> with Swastik Associates</span>
                                             </h1>
                                        </div>
                                   </div>
                                   <div className="col-lg-5 col-md-6 col-sm-12 " style={{ marginTop: "60px" }}>
                                        {/* <div>
                                             <img
                                                  decoding="async"
                                                  width=""
                                                  height=""
                                                  src={Mike}
                                                  className="attachmentFully"
                                                  alt=""
                                                  loading="lazy"
                                                  sizes="(max-width: 300px) 100vw, 300px"
                                             />
                                             <img
                                                  decoding="async"
                                                  width=""
                                                  height=""
                                                  src={John1}
                                                  className="attachmentFull"
                                                  alt=""
                                                  loading="lazy"
                                                  sizes="(max-width: 300px) 100vw, 300px"
                                             />
                                             <img
                                                  decoding="async"
                                                  width=""
                                                  height=""
                                                  src={John}
                                                  className="attachmentFull"
                                                  alt=""
                                                  loading="lazy"
                                                  sizes="(max-width: 300px) 100vw, 300px"
                                             />
                                             <pre style={{ marginLeft: "211px", marginTop: "-64px" }}>
                                                  <span className="homeHeaders">125+</span>
                                                  <br></br>
                                                  <span className="homePeras">Satisfying Customers</span>
                                             </pre>
                                        </div> */}

                                        {/* <h6 className="homeHeader">HOME &nbsp; &nbsp;  &gt; &nbsp; &nbsp;   PRICING</h6> */}
                                        <p
                                             className=" text-sm-start top-element-formatting"
                                             style={{ marginTop: "0px" }}
                                        >
                                             At Swastik Enterprises, we deliver cutting-edge software solutions tailored
                                             to your business needs. Experience the power of innovation and efficiency
                                             as you propel your business to new heights. Choose Swastik Enterprises for
                                             software that evolves with you.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section style={{ marginTop: "20px" }} className="fadeInUp">
                    <img src={serBanner} className="imgFluid" alt="Service Banner" />
               </section>

               <section>
                    <div className="container-fluid fadeInUp" style={{ padding: "120px 0px 120px 0px" }}>
                         <div className="container fadeInUp">
                              <div className="row">
                                   <div className="text-center" style={{ marginBottom: "70px" }}>
                                        <span className="servicesIcons">SERVICES</span>
                                        <h2 className="servicesPara">
                                             Enhance Your <span style={{ color: "#4D52D9" }}>Tech Performance</span> for
                                             <span style={{ color: "#4D52D9" }}> Optimal Results</span>
                                        </h2>
                                        <p className="iconPera mBox" style={{ padding: "0% 20% 0% 20%" }}>
                                             As a leading provider of ERP technology tools, we excel in optimizing and
                                             streamlining business operations for the industry. Our cutting-edge
                                             solutions are crafted to enhance efficiency, ensuring a seamless and
                                             professional ERP experience. Embrace operational excellence with our
                                             tailored tools designed to meet the unique needs of your ERP business.
                                        </p>
                                   </div>
                                   <div className="col-lg-3 col-md-6 col-sm-12 text-center">
                                        <span className="servicesIcon">
                                        <img src={retailSoftware} className="imgFluid" alt="Service Banner" />
                                        </span>
                                        <h3 className="mb-3 mt-4 iconHeder">Retail Software</h3>
                                        {/* <p className="iconPera">
                                             Empower Your Business with GST Software: Leading the Way in GST Solutions
                                        </p> */}
                                   </div>
                                   <div className="col-lg-3 col-md-6 col-sm-12 text-center">
                                        <span className="servicesIcon">
                                        <img src={distribution_software} className="imgFluid" alt="Service Banner" />
                                        </span>

                                        <h3 className="mb-3 mt-4 iconHeder">Distribution Software</h3>
                                        {/* <p className="iconPera">
                                             Revolutionize Your Pharmacy Operations with Advanced Pharmacy Software
                                             Minimize Expiry Loss and Streamline
                                        </p> */}
                                   </div>
                                   <div className="col-lg-3 col-md-6 col-sm-12 text-center">
                                        <span className="servicesIcon">
                                        <img src={manufacturing_software} className="imgFluid" alt="Service Banner" />
                                        </span>

                                        <h3 className="mb-3 mt-4 iconHeder">Manufacturing Software</h3>
                                        {/* <p className="iconPera">Elevate Your Financial Management Experience</p> */}
                                   </div>
                                   <div className="col-lg-3 col-md-6 col-sm-12 text-center">
                                        <span className="servicesIcon">
                                        <img src={gst_software} className="imgFluid" alt="Service Banner" />
                                        </span>

                                        <h3 className="mb-3 mt-4 iconHeder">GST Software</h3>
                                        
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               {/* <section style={{ marginRight: "auto", marginLeft: "auto", marginTop: "30px", marginBottom: "30px" }}>
                    <div className="container-fluid fadeInUp">
                         <div className="container">
                              <div className="row clientBx">
                                   <div className="col-lg-4 col-md-12 col-sm-12">
                                        <h3 className="servicesPara">
                                             Our <span style={{ color: "#4D52D9" }}> Clients </span>
                                        </h3>
                                        <p className="iconPera">
                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                                        </p>
                                   </div>
                                   <div className="col-lg-4 col-md-6 col-sm-6">
                                        <img src={aboutLogo1} style={{ padding: "0 30px 30px 0" }} alt="logo"></img>
                                        <img src={aboutLogo3} style={{ padding: "0 30px 30px 0" }} alt="logo"></img>
                                   </div>
                                   <div className="col-lg-4 col-md-6 col-sm-6">
                                        <img src={aboutLogo2} style={{ padding: "0 30px 30px 0" }} alt="logo"></img>
                                        <img src={aboutLogo4} style={{ padding: "0 30px 30px 0" }} alt="logo"></img>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section> */}
               <section>
                    <div className="container-fluid ">
                         <div className="container ">
                              <div className="row pview fadeInUp" style={{ padding: "120px 0px 120px 0px" }}>
                                   <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                                        <div
                                             className="card  imgHeght"
                                             style={{
                                                  backgroundImage: `url(${work_process})`,
                                                  backgroundSize: "cover",
                                                  backgroundPosition: "center",
                                                  // height: '400px',
                                                  border: "none",
                                                  height: "100%",
                                                  width: "100%",
                                                  borderRadius: "30px 30px 30px 30px",
                                                  // margin: "0px 0px 0px 60px",
                                                  padding: "30px 30px 30px 30px",
                                             }}
                                        >
                                             {/* <div className="outterDiv">
                                                  <div className="">
                                                       <div className="elementorwidget">
                                                            <div className="elementoricon">
                                                                 <span className="">
                                                                      <span className="aiImg aiInsise">
                                                                           <FontAwesomeIcon
                                                                                icon={faUserShield}
                                                                                className="aiIcon aiinside"
                                                                           />
                                                                      </span>
                                                                 </span>
                                                            </div>
                                                            <div className="elementor-icon-box-content">
                                                                 <h4 className="elementorIcon">
                                                                      <span>Automatic Sync </span>
                                                                 </h4>
                                                                 <p className="iconInside">
                                                                      Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                      elit.{" "}
                                                                 </p>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div> */}
                                        </div>
                                   </div>
                                   <div
                                        className="col-lg-6 col-md-12 col-sm-12 pview fadeInUp"
                                        style={{ padding: "0px 0px 0px 60px" }}
                                   >
                                        <span className="servicesIcons">PROCESS</span>
                                        <h3 className="servicesPara">
                                             Improve Business <span style={{ color: "#4D52D9" }}> Process </span>{" "}
                                             Efficiency
                                             <span style={{ color: "#4D52D9" }}> Enhance </span> Operational Efficiency
                                             for Streamlined Business Processes.
                                        </h3>
                                        <p className="iconPera text-sm-start">
                                             Revolutionize your business with heightened efficiency. Our tailored
                                             solutions optimize processes, fostering agility and productivity.
                                             Seamlessly integrate advanced technologies, empowering your team and
                                             unlocking new levels of performance. Embrace a future where streamlined
                                             operations propel your business forward, making every task more efficient
                                             and every outcome more impactful.
                                        </p>
                                        <FaqLanding
                                             extraClasses=""
                                             title="Efficient Operations"
                                             detials="Streamline business workflows by digitizing booking processes, itineraries, and customer interactions. Automation enhances efficiency, reduces errors, and allows staff to focus on delivering exceptional ERP experiences"
                                        />

                                        <FaqLanding
                                             extraClasses=""
                                             title="Data-Driven Decisions"
                                             detials="Leverage digital tools for comprehensive data analytics, gaining valuable insights into customer preferences and market trends. Informed decision-making ensures targeted marketing, personalized services, and a competitive edge in the ERP industry.
                                             "
                                        />
                                        <FaqLanding
                                             extraClasses=""
                                             title="Adaptability and Innovation"
                                             detials="Embrace digitalization to stay agile in a dynamic industry. Adopting innovative technologies such as AI-powered chatbots, virtual reality for virtual tours, and mobile apps ensures your ERP business remains competitive and responsive to evolving customer expectations.                                             "
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="container-fluid">
                    <div className="container fadeInUp" style={{ padding: "120px 0px 90px 0px" }}>
                         <div className="row">
                              <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                                   <span className="servicesIcons">FEATURES</span>
                                   <h3 className="servicesPara">
                                        AI-Enhanced Software for{" "}
                                        <span style={{ color: "#4D52D9" }}> Precision Engagement</span>
                                   </h3>
                                   <p className="iconPera">
                                        Empower your business with the latest AI-driven CRM for enhanced efficiency and
                                        personalized customer engagement.
                                   </p>
                                   <div className="row" style={{ marginTop: "40px" }}>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faSquareRss} className="aiIcon" />
                                             </span>
                                             <h4 className="aiheder">Pharmacy Software</h4>
                                             <p className="iconPera">
                                                  Revolutionize Your Pharmacy Operations with Advanced Pharmacy Software
                                             </p>
                                             {/* <Button title="See more" type="primary" /> */}
                                             <Link to="/services/2">See more</Link>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faUserShield} className="aiIcon" />
                                             </span>

                                             <h4 className="aiheder">Quick Heal Antivirus</h4>
                                             <p className="iconPera">Safeguarding Your Digital World with Precision</p>
                                             <Link to="/services/7">See more</Link>
                                        </div>
                                   </div>
                                   <div className="row " style={{ marginTop: "50px", marginBottom: "0px" }}>
                                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faSquareRss} className="aiIcon" />
                                             </span>
                                             <h4 className="aiheder">FMCG Software</h4>
                                             <p className="iconPera">
                                                  Effortlessly manage your fast-paced inventory with precision. Our
                                                  FMCG.
                                             </p>
                                             <Link to="/services/8">See more</Link>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faUserShield} className="aiIcon" />
                                             </span>

                                             <h4 className="aiheder">Mobile Billing Software</h4>
                                             <p className="iconPera">Empowering Your Business Mobility</p>
                                             <Link to="/services/9">See more</Link>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                                   <div
                                        className="card  imgHeght"
                                        style={{
                                             backgroundImage: `url(${Mission1})`,
                                             backgroundSize: "cover",
                                             backgroundPosition: "center",

                                             border: "none",
                                             height: "100%",
                                             width: "100%",
                                             borderRadius: "30px 30px 30px 30px",
                                             // margin: "0px 0px 0px 60px",
                                             padding: "30px 30px 30px 30px",
                                        }}
                                   >
                                        <div className="outterDiv">
                                             <div className="">
                                                  <div className="elementorwidget">
                                                       <div className="elementoricon">
                                                            <span className="">
                                                                 <span className="aiImg aiInsise">
                                                                      <FontAwesomeIcon
                                                                           icon={faUserShield}
                                                                           className="aiIcon aiinside"
                                                                      />
                                                                 </span>
                                                            </span>
                                                       </div>
                                                       <div className="elementor-icon-box-content">
                                                            <h4 className="elementorIcon">
                                                                 <span>Innovative Creations</span>
                                                            </h4>
                                                            <p className="iconInside">
                                                                 Pioneering innovative creations that redefine
                                                                 possibilities and elevate experiences
                                                            </p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section style={{ backgroundColor: "#F3F3F3", paddingBottom: "130px" }}>
                    <div className="container-fluid fadeInUp">
                         <div className="container">
                              <div className="row" style={{ padding: "120px 0px 0px 0px" }}>
                                   <div
                                        className="col-lg-6 col-md-6 col-sm-12 pview"
                                        style={{ padding: "0px 120px 0px 0px" }}
                                   >
                                        <span className="servicesIcons">WHAT WE DO</span>
                                        <h3 className="servicesPara">
                                             Smart Technology to <span style={{ color: "#4D52D9" }}>Maximize</span>{" "}
                                             Productivity
                                        </h3>
                                   </div>
                                   <div
                                        className="col-lg-6 col-md-6 col-sm-12 pview"
                                        style={{ padding: "60px 0px 5px 200px" }}
                                   >
                                        <p className="iconPera">
                                             Deploying smart tech for peak productivity and seamless operational
                                             efficiency.
                                        </p>
                                   </div>
                              </div>
                              <div
                                   className="row gap-5 align-items-center justify-content-center"
                                   style={{ marginTop: "40px" }}
                              >
                                   {services.map(({ title, path }: { title: string; path: string }) => (
                                        <div key={title} className="col-lg-3 col-md-3 col-sm-12 mPading">
                                             <Link to={`/services/${path}`} style={{ textDecoration: "none" }}>
                                                  <div className="whatwedoStyles">
                                                       <p className="boxParas">{title}</p>
                                                  </div>
                                             </Link>
                                        </div>
                                   ))}
                              </div>
                         </div>
                    </div>
               </section>

               {/* <section className="container-fluid fadeInUp" style={{ padding: "120px 0px 60px 0px" }}>
                    <div className="container" style={{ maxWidth: "1240px" }}>
                         <div className="row fadeInUp">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                   <div className="text-center">
                                        <span className="servicesIcons">NEWS</span>
                                        <h2 className="servicesPara">
                                             Read Latest Tech <span style={{ color: "#4D52D9" }}>Insights</span>
                                        </h2>
                                        <p className="iconPera" style={{ padding: "0% 20% 0% 20%" }}>
                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,
                                             luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div className="row fadeInUp" style={{ marginTop: "30px" }}>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                   <div className="blogStyle">
                                        <div>
                                             <img src={blog1} className="blogImag" alt="logo"></img>
                                        </div>
                                        <h4 className="bloghading">How Technology Affects Our Future</h4>
                                        <span className="servicesIcon">
                                             <FaCalendar className="aiIcon" />
                                        </span>
                                        <span className="blogDate">June 10, 2023</span>
                                        <span className="servicesIcon">
                                             <FaFolder className="aiIcon" />
                                        </span>
                                        <span className="blogDate">Technology</span>
                                   </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                   <div className="blogStyle">
                                        <img src={blog2} className="blogImag" alt="logo"></img>
                                        <h4 className="bloghading">When Cloud Computing Change the Ecosystem</h4>
                                        <span className="servicesIcon">
                                             <FaCalendar className="aiIcon" />
                                        </span>
                                        <span className="blogDate">June 10, 2023</span>
                                        <span className="servicesIcon">
                                             <FaFolder className="aiIcon" />
                                        </span>
                                        <span className="blogDate">Technology</span>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section> */}

               {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px fadeInUp">
                    <Card extraClasses="px-sm-30 py-sm-2rem p-100">
                         <div className="d-grid-33-66">
                              <div className=" w-sm-100">
                                   <span className="servicesIcons">FEATURES</span>
                                   <h2 className="servicesPara">
                                        Client <span style={{ color: "#4D52D9" }}>Reviews</span>
                                   </h2>
                                   <FaArrowLeft
                                        className="color-blue me-5"
                                        style={{ fontSize: "20px", color: "#4D52D9" }}
                                   />
                                   <FaArrowRight
                                        className="color-blue"
                                        style={{ fontSize: "20px", color: "#4D52D9" }}
                                   />
                              </div>
                              <Card>
                                   <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true}
                                        autoplay={{
                                             delay: 3000,
                                             disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                   >
                                        <SwiperSlide>
                                             <Testimonial
                                                  client_img={<John />}
                                                  client_name="Mike Lewis"
                                                  designation="Twitter"
                                                  content="Lacus sed turpis tincidunt id. Sagittis nisl rhoncus mattis rhoncus. In eu mi bibendum neque egestas congue quisque. Risus nec feugiat in fermentum posuere urna. Nulla pellentesque dignissim enim sit amet venenatis. Mauris pharetra et ultrices neque."
                                             />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                             <Testimonial
                                                  client_img={<Mike />}
                                                  client_name="John Doe"
                                                  designation="Google"
                                                  content="Lacus sed turpis tincidunt id. Sagittis nisl rhoncus mattis rhoncus. In eu mi bibendum neque egestas congue quisque. Risus nec feugiat in fermentum posuere urna. Nulla pellentesque dignissim enim sit amet venenatis. Mauris pharetra et ultrices neque."
                                             />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                             <Testimonial
                                                  client_img={<John1 />}
                                                  client_name="John Doe"
                                                  designation="Google"
                                                  content="Lacus sed turpis tincidunt id. Sagittis nisl rhoncus mattis rhoncus. In eu mi bibendum neque egestas congue quisque. Risus nec feugiat in fermentum posuere urna. Nulla pellentesque dignissim enim sit amet venenatis. Mauris pharetra et ultrices neque."
                                             />
                                        </SwiperSlide>
                                   </Swiper>
                              </Card>
                         </div>
                    </Card>
               </section>
               <section
                    className="container-fluid"
                    style={{ backgroundColor: "#F3F3F3", padding: "120px 0px 120px 0px" }}
               >
                    <div className="container">
                         <div className="row ">
                              <div className="text-center fadeInUp" style={{ marginBottom: "50px" }}>
                                   <span className="servicesIcons">PLAN</span>
                                   <h2 className="servicesPara">
                                        Simple <span style={{ color: "#4D52D9" }}>Plan</span> for Business
                                   </h2>
                                   <p className="iconPera mBox" style={{ padding: "0% 20% 0% 20%" }}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus
                                        nec ullamcorper mattis, pulvinar dapibus leo.
                                   </p>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 fadeInUp">
                                   <div
                                        style={{
                                             padding: "20px 20px 20px 20px",
                                             backgroundColor: "#fff",
                                             boxShadow: "0px 20px 50px 0px rgba(161, 161, 161, 0.12)",
                                             borderRadius: "10px 10px 10px 10px",
                                        }}
                                   >
                                        <div className="text-center" style={{ padding: "40px 40px 40px 40px" }}>
                                             <div>
                                                  <FaQrcode
                                                       className="aiIcon"
                                                       style={{ margin: "auto", fontSize: "50px", color: "#4D52D9" }}
                                                  />
                                             </div>
                                             <h6 className="basicPricing">Basic</h6>
                                             <p className="iconPera">
                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                             </p>
                                             <span>
                                                  <sup className="dprice">₹</sup>
                                                  <span className="dPrices">8991</span>
                                                  <sup className="dmprice">/mo</sup>
                                             </span>
                                             <br></br>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Startup Service</span>
                                             </div>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Market Analytic</span>
                                             </div>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Ebook Guide</span>
                                             </div>
                                             <button
                                                  className="btnChoose"
                                                  style={{ marginTop: "50px" }}
                                                  // onClick={handleFormSubmit}
                                             >
                                                  Choose Plan
                                             </button>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 mViews fadeInUp">
                                   <div
                                        style={{
                                             padding: "20px 20px 20px 20px",
                                             backgroundColor: "#E9E9F7",
                                             boxShadow: "0px 20px 50px 0px rgba(161, 161, 161, 0.12)",
                                             borderRadius: "10px 10px 10px 10px",
                                        }}
                                   >
                                        <div className="text-center" style={{ padding: "40px 40px 40px 40px" }}>
                                             <div>
                                                  <FaWifi
                                                       className="aiIcon"
                                                       style={{ margin: "auto", fontSize: "50px", color: "#4D52D9" }}
                                                  />
                                             </div>
                                             <h6 className="basicPricing">Premium</h6>
                                             <p className="iconPera">
                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                             </p>
                                             <span>
                                                  <sup className="dprice">₹</sup>
                                                  <span className="dPrices">12600</span>
                                                  <sup className="dmprice">/mo</sup>
                                             </span>
                                             <br></br>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Cloud Computing</span>
                                             </div>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Automatic Backup</span>
                                             </div>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Digital Device</span>
                                             </div>
                                             <button
                                                  className="btnChoose btnHover"
                                                  style={{ marginTop: "50px" }}
                                                  // onClick={handleFormSubmit}
                                             >
                                                  Choose Plan
                                             </button>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 mViews tabViews fadeInUp">
                                   <div
                                        style={{
                                             padding: "20px 20px 20px 20px",
                                             backgroundColor: "#fff",
                                             boxShadow: "0px 20px 50px 0px rgba(161, 161, 161, 0.12)",
                                             borderRadius: "10px 10px 10px 10px",
                                        }}
                                   >
                                        <div className="text-center" style={{ padding: "40px 40px 40px 40px" }}>
                                             <div>
                                                  <FaArchive
                                                       className="aiIcon"
                                                       style={{ margin: "auto", fontSize: "50px", color: "#4D52D9" }}
                                                  />
                                             </div>
                                             <h6 className="basicPricing">Advance</h6>
                                             <p className="iconPera">
                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                             </p>
                                             <span>
                                                  <sup className="dprice">₹</sup>
                                                  <span className="dPrices">25600</span>
                                                  <sup className="dmprice">/mo</sup>
                                             </span>
                                             <br></br>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Corporate Analytic</span>
                                             </div>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Budget Plan</span>
                                             </div>
                                             <div style={{ marginBottom: "15px", fontSize: "15px" }}>
                                                  <FaCheckSquare
                                                       className="aiPIcon"
                                                       style={{ marginRight: "10px", color: "#4D52D9" }}
                                                  />
                                                  <span>Market Plan</span>
                                             </div>
                                             <button className="btnChoose" style={{ marginTop: "50px" }}>
                                                  Choose Plan
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section> */}

               <section
                    className="containerFluid"
                    style={{
                         backgroundImage: `url(${bnImg})`,
                         backgroundSize: "cover",
                         backgroundPosition: "center center",
                         backgroundRepeat: "no-repeat",
                         padding: "120px 0px 120px 0px",
                         position: "relative",
                    }}
               >
                    <div className="insideImage"></div>
                    <div className="container">
                         <div className="row fadeInUp">
                              <div
                                   className="col-lg-10 col-md-10 col-sm-12 mBox"
                                   style={{ position: "relative", padding: "0px 163px 0px 0px" }}
                              >
                                   <p className="bnerpara psize">
                                        Get in touch with us for any questions, concerns, or feedback.
                                   </p>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-12 btbSize" style={{ position: "relative" }}>
                                   <button className="btnChoose">Get Started </button>
                              </div>

                              <div></div>
                         </div>
                    </div>
               </section>
          </Fragment>
     );
};

export default HomePage;
