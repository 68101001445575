import React from "react";
import { Fragment } from "react";
import "swiper/css";
import Helmet from "react-helmet";
import { useServiceSlice } from "../redux/features";
import { Link } from "react-router-dom";

const Services = () => {
     const { services } = useServiceSlice();
     return (
          <Fragment>
               <Helmet>
                    <title>Services</title>
                    <meta name="description" content="Why Choose OPAS?" />
               </Helmet>
               <section>
                    <div className="container-fluid" style={{ backgroundColor: "#FAFAFA" }}>
                         <div className="container">
                              <div
                                   className="row align-items-center rowPading fadeInUp"
                                   style={{ padding: "10px 0px 0px 0px" }}
                              >
                                   <div className="col-lg-7 col-md-6 col-sm-12">
                                        <div>
                                             <h1 className="techHader">
                                                  Tech <span style={{ color: "#4D52D9" }}>Services</span> for
                                                 
                                                  Business
                                             </h1>
                                        </div>
                                   </div>
                                   {/* <div className="col-lg-5 col-md-6 col-sm-12" style={{ marginTop: "60px" }}>
                                        <h6 className="homeHeader">HOME &nbsp; &nbsp; &gt; &nbsp; &nbsp; SERVICES</h6>
                                        <p className="homePera">
                                            
                                        </p>
                                   </div> */}
                              </div>
                         </div>
                    </div>
               </section>
               {/* <section style={{ marginTop: "80px" }} className="">
                    <img src={serBanner} className="imgFluid fadeInUp" alt="Service Banner" />
               </section> */}

               <section style={{ backgroundColor: "#F3F3F3", paddingBottom: "130px" }}>
                    <div className="container-fluid fadeInUp">
                         <div className="container">
                              <div className="row" style={{ padding: "120px 0px 0px 0px" }}>
                                   <div
                                        className="col-lg-12 col-md-12 col-sm-12 pview"
                                        style={{ padding: "0px 120px 0px 0px" }}
                                   >
                                        <span className="servicesIcons">WHAT WE DO</span>
                                        <h3 className="servicesPara">
                                             Smart Technology to <span style={{ color: "#4D52D9" }}>Maximize</span>{" "}
                                             Productivity
                                        </h3>
                                   </div>
                                   <div
                                        className="col-lg-6 col-md-6 col-sm-12 pview"
                                        style={{ padding: "60px 0px 5px 200px" }}
                                   >
                                        <p className="iconPera">
                                             {/* Deploying smart tech for peak productivity and seamless operational
                                             efficiency. */}
                                        </p>
                                   </div>
                              </div>
                              <div
                                   className="row gap-5 align-items-center justify-content-center"
                                   style={{ marginTop: "40px" }}
                              >
                                   {services.map(({ title, path }: { title: string; path: string }) => (
                                        <div key={title} className="col-lg-3 col-md-3 col-sm-12 mPading">
                                             <Link to={`/services/${path}`} style={{ textDecoration: "none" }}>
                                                  <div className="whatwedoStyles">
                                                       <p className="boxParas">{title}</p>
                                                  </div>
                                             </Link>
                                        </div>
                                   ))}
                              </div>
                         </div>
                    </div>
               </section>
          </Fragment>
     );
};

export default Services;
