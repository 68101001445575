import React, { FC, Fragment } from "react";
import Helmet from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareRss, faUserShield } from "@fortawesome/free-solid-svg-icons";
import Mission from "../assets/hyb3.jpg";
import "bootstrap/dist/css/bootstrap.min.css";

// Define the interface for ContactUsProps if your component uses props
interface ContactUsProps {}

// Functional Component with TypeScript annotations
const ContactUs: FC<ContactUsProps> = () => {
     return (
          <Fragment>
               {/* Helmet for managing head tags */}
               <Helmet>
                    <title>Contact Us</title>
                    <meta name="description" content="Contact Us" />
               </Helmet>

               {/* First section */}
               <section>
                    <div className="container-fluid" style={{ backgroundColor: "#FAFAFA" }}>
                         <div className="container fadeInUp">
                              <div
                                   className="row align-items-center rowPading"
                                   style={{ padding: "10px 0px 0px 0px" }}
                              >
                                   <div className="col-lg-7 col-md-6 col-sm-12">
                                        <div>
                                             <h1 className="techHader">
                                                  <span style={{ color: "#4D52D9" }}>Contact</span> Us and Get Started
                                             </h1>
                                        </div>
                                   </div>
                                   <div className="col-lg-5 col-md-6 col-sm-12" style={{ marginTop: "60px" }}>
                                        <h6 className="homeHeader">HOME &nbsp; &nbsp; &gt; &nbsp; &nbsp; CONTACT</h6>
                                        <p className="homePera">
                                             Join us as we explore the ever-evolving landscape of technology and
                                             discover how it can transform your life.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               {/* Second section */}
               <section className="container-fluid">
                    <div className="container" style={{ padding: "120px 0px 90px 0px", maxWidth: "1240px" }}>
                         <div className="row fadeInUp">
                              {/* Left column */}
                              <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                                   <span className="servicesIcons">CONTACT</span>
                                   <h3 className="servicesPara">
                                        Get in <span style={{ color: "#4D52D9" }}>Touch</span>
                                   </h3>
                                   <p className="iconPera">Reach Out for Inquiries, Support, and Collaborations</p>

                                   {/* Nested rows for contact details */}
                                   <div className="row" style={{ marginTop: "40px" }}>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faSquareRss} className="aiIcon" />
                                             </span>
                                             <h4 className="aiheder">+91-9370814581 </h4>
                                             <p className="iconPera">
                                             WhatsApp Chat Support 
Service (24x7)
                                             </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faSquareRss} className="aiIcon" />
                                             </span>
                                             <h4 className="aiheder">Visit Us</h4>
                                             <p className="iconPera">
                                             Shop No. 3 ,SAI VAIBHAV PLAZA, 
opp. Police Chowki, behind Chitnis Park, 
Jalalpura, Mahal, Nagpur, Maharashtra 440032
Landmark: Jalalpura Police Chowki
                                             </p>
                                        </div>
                                        
                                   </div>

                                   <div className="row" style={{ marginTop: "50px", marginBottom: "0px" }}>
                                   <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faUserShield} className="aiIcon" />
                                             </span>
                                             <h4 className="aiheder">Send email</h4>
                                             <p className="iconPera">
                                             <a href="mailto:contact@margerpngp.in">contact@margerpngp.in</a>
                                             </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                             <span className="aiImg">
                                                  <FontAwesomeIcon icon={faUserShield} className="aiIcon" />
                                             </span>
                                             <h4 className="aiheder">Call Us</h4>
                                             <p className="iconPera">
                                                 <a href="tel:+919370814581">+91-9370814581</a>
                                             </p>
                                        </div>
                                   </div>
                              </div>

                              {/* Right column */}
                              <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                                   <div
                                        className="card imgHeght "
                                        style={{
                                             backgroundImage: `url(${Mission})`,
                                             backgroundSize: "cover",
                                             backgroundPosition: "center",
                                             border: "none",
                                             height: "100%",
                                             width: "100%",
                                             borderRadius: "30px 30px 30px 30px",
                                             padding: "30px 30px 30px 30px",
                                        }}
                                   >
                                        <div className="outterDiv">
                                             <div className="">
                                                  <div className="elementorwidget">
                                                       <div className="elementoricon">
                                                            <span className="">
                                                                 <span className="aiImg aiInsise">
                                                                      <FontAwesomeIcon
                                                                           icon={faUserShield}
                                                                           className="aiIcon aiinside"
                                                                      />
                                                                 </span>
                                                            </span>
                                                       </div>
                                                       <div className="elementor-icon-box-content">
                                                            <h4 className="elementorIcon">
                                                                 <span>Get Connected</span>
                                                            </h4>
                                                            <p className="iconInside">
                                                                 Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                 elit.
                                                            </p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </Fragment>
     );
};

export default ContactUs;
