import React from "react";
import { Fragment } from "react";
import "swiper/css";
import Helmet from 'react-helmet';
import serBanner from "../assets/featureBanner.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSquareRss, faUserShield} from '@fortawesome/free-solid-svg-icons';
import Mission from "../assets/hyb13.jpg"
import Mission1 from "../assets/hyb4.jpg";
import { FaCheck } from "react-icons/fa";

const Feature = () => {
  

  return (
    <Fragment>
      <Helmet>
        <title>
          FEATURES
        </title>
        <meta
          name="description"
          content="Our Consultants"
        />
      </Helmet>
      <section>
        <div className="container-fluid fadeInUp" style={{backgroundColor:"#FAFAFA"}}>
          <div className="container">
            <div className="row rowPading" style={{ padding: "10px 0px 0px 0px" }}>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div>
                  <h1 className="techHader">Tech <span style={{ color: "#4D52D9" }}>Features</span> for Business</h1>
                </div>

              </div>
              <div className="col-lg-5 col-md-6 col-sm-12" style={{ marginTop: "60px" }}>
                <h6 className="homeHeader">HOME &nbsp; &nbsp;  &gt; &nbsp; &nbsp;   FEATURES</h6>
                <p className="homePera">Join us as we explore the ever-evolving landscape of technology and discover how it can transform your life.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "80px" }} className="fadeInUp">
        <img src={serBanner} className="imgFluid fadeInUp" alt="Service Banner" />
      </section>
      <section className="container-fluid" >
        <div className="container" style={{ padding: "10px 0px 90px 0px" }}>
          <div className="row fadeInUp">
            <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
              <span className="servicesIcons">
                FEATURES
              </span>
              <h3 className="servicesPara">
                Equipped with Recent Technology <span style={{ color: "#4D52D9" }}>Features</span>
              </h3>
              <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
              <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span className="aiImg">
                    <FontAwesomeIcon icon={faSquareRss} className="aiIcon" />

                  </span>
                  <h4 className="aiheder">AI Powered</h4>
                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span className="aiImg">

                    <FontAwesomeIcon icon={faUserShield} className="aiIcon" />
                  </span>

                  <h4 className="aiheder">Robotic</h4>
                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
              </div>
              <div className="row" style={{ marginTop: "50px", marginBottom: "0px" }}>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span className="aiImg">
                    <FontAwesomeIcon icon={faSquareRss} className="aiIcon" />

                  </span>
                  <h4 className="aiheder">VR & AR</h4>
                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span className="aiImg">

                    <FontAwesomeIcon icon={faUserShield} className="aiIcon" />
                  </span>

                  <h4 className="aiheder">Touch ID</h4>
                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
              <div className="card  imgHeght" style={{
                backgroundImage: `url(${Mission1})`, backgroundSize: 'cover',
                backgroundPosition: 'center',
      
                border: "none", height: "100%",
                width: "100%",
                borderRadius: "30px 30px 30px 30px",
                // margin: "0px 0px 0px 60px",
                padding: "30px 30px 30px 30px"
              }}>
                <div className="outterDiv">
                  <div className="">
                    <div className=" elementorwidget">
                      <div className="elementoricon">
                        <span className="">
                          <span className="aiImg aiInsise">

                            <FontAwesomeIcon icon={faUserShield} className="aiIcon aiinside" />
                          </span>
                        </span>
                      </div>
                      <div className="elementor-icon-box-content">
                        <h4 className="elementorIcon">
                          <span>
                            Innovative Creations					</span>
                        </h4>
                        <p className="iconInside">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.					</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid" >
        <div className="container" style={{ padding: "120px 0px 90px 0px" }}>
          <div className="row fadeInUp fadeInUp" >
            <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
              <div className="card  imgHeght" style={{
                backgroundImage: `url(${Mission})`, backgroundSize: 'cover',
                backgroundPosition: 'center',
          
                border: "none", height: "100%",
                width: "100%",
                borderRadius: "30px 30px 30px 30px",
                // margin: "0px 0px 0px 60px",
                padding: "30px 30px 30px 30px"
              }}>
                <div className="outterDiv">
                  <div className="">
                    <div className=" elementorwidget">
                      <div className="elementoricon">
                        <span className="">
                          <span className="aiImg aiInsise">

                            <FontAwesomeIcon icon={faUserShield} className="aiIcon aiinside" />
                          </span>
                        </span>
                      </div>
                      <div className="elementor-icon-box-content">
                        <h4 className="elementorIcon">
                          <span>
                            Hi-Tech Datacenter					</span>
                        </h4>
                        <p className="iconInside">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.					</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 pview1 fadeInUp" style={{ padding: "0px 0px 0px 60px" }}>
              <span className="servicesIcons">
                SECURE
              </span>
              <h3 className="servicesPara">
                Enabling <span style={{ color: "#4D52D9" }}> Immersive</span> Experiences in Tech World
              </h3>
              <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
              <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-lg-2 col-md-2 col-sm-2">

                  <span className="aiImgF">
                    {/* <FontAwesomeIcon icon={faSquareRss} className="aiIcon" /> */}
                    <FaCheck className="aiIcon" />
                  </span>

                </div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h4 className="aihederF">5G Connectivity</h4>

                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">

                  <span className="aiImgF">
                    {/* <FontAwesomeIcon icon={faSquareRss} className="aiIcon" /> */}
                    <FaCheck className="aiIcon" />
                  </span>

                </div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h4 className="aihederF">Artificial Intelligence Integration</h4>

                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">

                  <span className="aiImgF">
                    {/* <FontAwesomeIcon icon={faSquareRss} className="aiIcon" /> */}
                    <FaCheck className="aiIcon" />
                  </span>

                </div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h4 className="aihederF">Advanced Biometric Authentication</h4>

                  <p className="iconPera">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus nec.</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Feature;
