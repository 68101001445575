import React, { Fragment } from "react";
import Helmet from "react-helmet";
import serBanner from "../assets/abiutBaner.jpg";
import aboutBanner from "../assets/aboutImage.jpg";
import abooutImg from "../assets/aboutImg2.jpg";
import Mission from "../assets/hyb3.jpg";
import FaqLanding from "../components/faq_landing/FaqLanding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
const AboutUs = () => {
     return (
          <Fragment>
               <Helmet>
                    <title></title>
                    <meta name="description" content="" />
               </Helmet>

               <section>
                    <div className="container-fluid  fadeInUp" style={{ backgroundColor: "#FAFAFA" }}>
                         <div className="container">
                              <div
                                   className="row align-items-center rowPading "
                                   style={{ padding: "10px 0px 0px 0px" }}
                              >
                                   <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div>
                                             <h1 className="techHader">
                                             Marg ERP -<br/>{" "}
                                                  <span style={{ color: "#4D52D9" }}>
                                                   Your Trusted Software Solution Provider
                                                  </span>
                                                  {/* <span style={{ color: "#4D52D9" }}>Expert</span> Since 1998 */}
                                             </h1>
                                        </div>
                                   </div>
                                   <div className="col-lg-8 col-md-8 col-sm-12" style={{ marginTop: "60px" }}>
                                        <h6 className="homeHeader">HOME &nbsp; &nbsp; &gt; &nbsp; &nbsp; ABOUT</h6>
                                        <p className="homePera">
                                        Marg ERP is a leading provider of comprehensive software solutions tailored to meet the diverse needs of businesses across India. With a rich history of innovation and a deep understanding of the Indian market, we have emerged as the preferred choice for organizations seeking reliable, efficient, and user-friendly software solutions.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section style={{ marginTop: "80px" }} className="fadeIn fadeInUp">
                    <img src={serBanner} className="imgFluid fadeInUp" alt="Service Banner" />
               </section>

               <section style={{ padding: "120px 0px 20px 0px" }}>
                    <div className="container-fluid ">
                         <div className="container fadeInUp">
                              <div className="row  fadeInUp fadeIn">
                                   <div
                                        className="col-lg-6 col-md-12 col-sm-12"
                                        style={{ padding: "0px 60px 0px 0px" }}
                                   >
                                        <span className="servicesIcons">OUR MISSION</span>
                                        <h3 className="servicesPara">
                                             Trusted Tech Company to <span style={{ color: "#4D52D9" }}> Boost </span>{" "}
                                             Your Business
                                        </h3>
                                        <p className="iconPera">
                                        Our mission at Marg ERP is to empower businesses with cutting-edge software solutions that streamline operations, enhance productivity, and drive growth. We are committed to delivering high-quality, customizable solutions that exceed our clients' expectations and enable them to achieve their business objectives effectively.
                                        </p>
                                        {/* <p className="iconPera">
                                             Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                             dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                             proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p> */}
                                        {/* <span className="webIcons">
                                             <div className="wedDiv87">
                                                  WEB SECURITY <span>87%</span>
                                             </div>
                                        </span>
                                        <span className="webIcons mt-4">
                                             <div className="wedDiv78">
                                                  ARTIFICIAL INTELLIGENCE <span>78%</span>
                                             </div>
                                        </span> */}
                                   </div>
                                   <div className="col-lg-6 col-md-12 col-sm-12 ">
                                        <img
                                             src={aboutBanner}
                                             alt="Service Banner"
                                             width="90%"
                                             height="50%"
                                             style={{ borderRadius: "20px 20px 20px 20px" }}
                                        ></img>
                                        <div className="row mt-5">
                                             {/* <div className="col-lg-3">
                                                  <img
                                                       className="imgAbout"
                                                       alt="Service Banner"
                                                       src={abooutImg}
                                                       width="80%"
                                                       style={{ borderRadius: "20px 20px 20px 20px" }}
                                                  ></img>
                                             </div> */}
                                             {/* <div className="col-lg-9">
                                                  <h6 className="fonderheder">VIKASH GUPTA - FOUNDER</h6>
                                                  <p className="founderPara">
                                                       "Swastik Technology is your ultimate source for staying ahead in
                                                       the dynamic world of technology."
                                                  </p>
                                             </div> */}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section style={{ padding: "20px 0px 50px 0px" }}>
                    <div className="container-fluid">
                         <div className="container fadeInUp">
                              <div className="row  fadeInUp fadeIn">
                                   <div className="col-lg-6 col-md-12 col-sm-12 ">
                                        <img
                                             src={aboutBanner}
                                             alt="Service Banner"
                                             width="90%"
                                             height="50%"
                                             style={{ borderRadius: "20px 20px 20px 20px" }}
                                        ></img>
                                        {/* <div className="row mt-5">
                                             <div className="col-lg-3">
                                                  <img
                                                       className="imgAbout"
                                                       alt="Service Banner"
                                                       src={abooutImg}
                                                       width="80%"
                                                       style={{ borderRadius: "20px 20px 20px 20px" }}
                                                  ></img>
                                             </div>
                                             <div className="col-lg-9">
                                                  <h6 className="fonderheder">JOHN DOE - FOUNDER</h6>
                                                  <p className="founderPara">
                                                       "Swastik Technology is your ultimate source for staying ahead in
                                                       the dynamic world of technology."
                                                  </p>
                                             </div>
                                        </div> */}
                                   </div>
                                   <div
                                        className="col-lg-6 col-md-12 col-sm-12"
                                        style={{ padding: "0px 60px 0px 0px" }}
                                   >
                                        <span className="servicesIcons">INNOVATION</span>
                                        <h3 className="servicesPara">
                                        Our Expertise with   <span style={{ color: "#4D52D9" }}> 15+ Year </span>{" "}
                                        Experience
                                        </h3>
                                        <p className="iconPera">
                                        With a team of experienced professionals and domain experts, Marg ERP brings a wealth of expertise to the table. Our software engineers, business analysts, and support staff work collaboratively to develop and deploy solutions that are tailored to the unique requirements of each client. We pride ourselves on our ability to understand the intricacies of various industries and deliver solutions that address specific pain points and challenges.
                                        </p>
                                        
                                        {/* <span className="webIcons">
                                             <div className="wedDiv87">
                                                  WEB SECURITY <span>87%</span>
                                             </div>
                                        </span>
                                        <span className="webIcons mt-4">
                                             <div className="wedDiv78">
                                                  ARTIFICIAL INTELLIGENCE <span>78%</span>
                                             </div>
                                        </span> */}
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section style={{ padding: "120px 0px 20px 0px" }}>
                    <div className="container-fluid ">
                         <div className="container fadeInUp">
                              <div className="row  fadeInUp fadeIn">
                                   <div
                                        className="col-lg-6 col-md-12 col-sm-12"
                                        style={{ padding: "0px 60px 0px 0px" }}
                                   >
                                        <span className="servicesIcons">Our Products</span>
                                        {/* <h3 className="servicesPara">
                                             Trusted Tech Company to <span style={{ color: "#4D52D9" }}> Boost </span>{" "}
                                             Your Business
                                        </h3> */}
                                        <p className="iconPera">
                                        Marg ERP offers a comprehensive suite of software products designed to streamline various aspects of business operations, including inventory management, accounting, taxation, and more. Our solutions are feature-rich, customizable, and scalable, catering to businesses of all sizes and industries. With features such as real-time data synchronization, mobile accessibility, and advanced reporting capabilities, our products empower businesses to make informed decisions and stay ahead of the competition.
                                        </p>
                                        {/* <p className="iconPera">
                                             Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                             dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                             proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p> */}
                                        {/* <span className="webIcons">
                                             <div className="wedDiv87">
                                                  WEB SECURITY <span>87%</span>
                                             </div>
                                        </span>
                                        <span className="webIcons mt-4">
                                             <div className="wedDiv78">
                                                  ARTIFICIAL INTELLIGENCE <span>78%</span>
                                             </div>
                                        </span> */}
                                   </div>
                                   <div className="col-lg-6 col-md-12 col-sm-12 ">
                                        <img
                                             src={aboutBanner}
                                             alt="Service Banner"
                                             width="90%"
                                             height="50%"
                                             style={{ borderRadius: "20px 20px 20px 20px" }}
                                        ></img>
                                        <div className="row mt-5">
                                             {/* <div className="col-lg-3">
                                                  <img
                                                       className="imgAbout"
                                                       alt="Service Banner"
                                                       src={abooutImg}
                                                       width="80%"
                                                       style={{ borderRadius: "20px 20px 20px 20px" }}
                                                  ></img>
                                             </div> */}
                                             {/* <div className="col-lg-9">
                                                  <h6 className="fonderheder">VIKASH GUPTA - FOUNDER</h6>
                                                  <p className="founderPara">
                                                       "Swastik Technology is your ultimate source for staying ahead in
                                                       the dynamic world of technology."
                                                  </p>
                                             </div> */}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section style={{ padding: "20px 0px 50px 0px" }}>
                    <div className="container-fluid">
                         <div className="container fadeInUp">
                              <div className="row  fadeInUp fadeIn">
                                   <div className="col-lg-6 col-md-12 col-sm-12 ">
                                        <img
                                             src={aboutBanner}
                                             alt="Service Banner"
                                             width="90%"
                                             height="50%"
                                             style={{ borderRadius: "20px 20px 20px 20px" }}
                                        ></img>
                                        {/* <div className="row mt-5">
                                             <div className="col-lg-3">
                                                  <img
                                                       className="imgAbout"
                                                       alt="Service Banner"
                                                       src={abooutImg}
                                                       width="80%"
                                                       style={{ borderRadius: "20px 20px 20px 20px" }}
                                                  ></img>
                                             </div>
                                             <div className="col-lg-9">
                                                  <h6 className="fonderheder">JOHN DOE - FOUNDER</h6>
                                                  <p className="founderPara">
                                                       "Swastik Technology is your ultimate source for staying ahead in
                                                       the dynamic world of technology."
                                                  </p>
                                             </div>
                                        </div> */}
                                   </div>
                                   <div
                                        className="col-lg-6 col-md-12 col-sm-12"
                                        style={{ padding: "0px 60px 0px 0px" }}
                                   >
                                        <span className="servicesIcons">Why Choose </span>
                                        <h3 className="servicesPara">
                                        Why Choose  <span style={{ color: "#4D52D9" }}> Marg ERP  </span>{" "}
                                        Software? 
                                        </h3>
                                        <p className="iconPera">
                                        Industry Experience: With years of experience serving clients across diverse industries, we have a deep understanding of the unique challenges faced by businesses in India.
Innovative Solutions: We are committed to continuous innovation, leveraging emerging technologies to develop cutting-edge solutions that drive efficiency and productivity.
Customer-Centric Approach: At Marg ERP, we prioritize customer satisfaction above all else. We work closely with our clients to understand their needs and deliver solutions that exceed their expectations.
Reliable Support: Our dedicated support team is available round-the-clock to provide assistance and guidance, ensuring a seamless implementation and operation of our software solutions.
Our Commitment

At Marg ERP, we are committed to empowering businesses with the tools they need to succeed in today's competitive market. We believe in building long-term partnerships with our clients based on trust, integrity, and mutual success. Whether you're a small startup or a large enterprise, you can rely on Marg ERP to provide you with the software solutions you need to thrive.

                                        </p>
                                        
                                        {/* <span className="webIcons">
                                             <div className="wedDiv87">
                                                  WEB SECURITY <span>87%</span>
                                             </div>
                                        </span>
                                        <span className="webIcons mt-4">
                                             <div className="wedDiv78">
                                                  ARTIFICIAL INTELLIGENCE <span>78%</span>
                                             </div>
                                        </span> */}
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               {/* <section style={{ marginRight: "auto", marginLeft: "auto", marginTop: "30px", marginBottom: "30px" }}>
                    <div className="container-fluid ">
                         <div className="container">
                              <div className="row clientBx  fadeInUp">
                                   <div className="col-lg-4 col-md-12 col-sm-12 fadeInUp">
                                        <h3 className="servicesPara">
                                             Our <span style={{ color: "#4D52D9" }}> Clients </span>
                                        </h3>
                                        <p className="iconPera">
                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                                        </p>
                                   </div>
                                   <div className="col-lg-4 col-md-6 col-sm-6">
                                        <img
                                             src={aboutLogo1}
                                             style={{ padding: "0 30px 30px 0" }}
                                             alt="Service Banner"
                                        ></img>
                                        <img
                                             src={aboutLogo3}
                                             style={{ padding: "0 30px 30px 0" }}
                                             alt="Service Banner"
                                        ></img>
                                   </div>
                                   <div className="col-lg-4 col-md-6 col-sm-6">
                                        <img
                                             src={aboutLogo2}
                                             style={{ padding: "0 30px 30px 0" }}
                                             alt="Service Banner"
                                        ></img>
                                        <img
                                             src={aboutLogo4}
                                             style={{ padding: "0 30px 30px 0" }}
                                             alt="Service Banner"
                                        ></img>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section> */}

               {/* <section>
                    <div className="container-fluid ">
                         <div className="container fadeInUp">
                              <div className="row pview  fadeInUp" style={{ padding: "120px 0px 120px 0px" }}>
                                   <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                                        <div
                                             className="card  imgHeght"
                                             style={{
                                                  backgroundImage: `url(${Mission})`,
                                                  backgroundSize: "cover",
                                                  backgroundPosition: "center",
                                                  // height: '400px',
                                                  border: "none",
                                                  height: "100%",
                                                  width: "100%",
                                                  borderRadius: "30px 30px 30px 30px",
                                                  // margin: "0px 0px 0px 60px",
                                                  padding: "30px 30px 30px 30px",
                                             }}
                                        >
                                             <div className="outterDiv">
                                                  <div className="">
                                                       <div className="elementorwidget">
                                                            <div className="elementoricon">
                                                                 <span className="">
                                                                      <span className="aiImg aiInsise">
                                                                           <FontAwesomeIcon
                                                                                icon={faUserShield}
                                                                                className="aiIcon aiinside"
                                                                           />
                                                                      </span>
                                                                 </span>
                                                            </div>
                                                            <div className="elementor-icon-box-content">
                                                                 <h4 className="elementorIcon">
                                                                      <span>Tech Expert</span>
                                                                 </h4>
                                                                 <p className="iconInside">
                                                                      Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                      elit.{" "}
                                                                 </p>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div
                                        className="col-lg-6 col-md-12 col-sm-12 pview fadeInUp"
                                        style={{ padding: "0px 0px 0px 60px" }}
                                   >
                                        <span className="servicesIcons">MISSION</span>
                                        <h3 className="servicesPara">
                                             Explore the <span style={{ color: "#4D52D9" }}> Ever-Evolving </span>{" "}
                                             Landscape of Technology
                                        </h3>
                                        <p className="iconPera">
                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,
                                             luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                        <FaqLanding
                                             extraClasses=""
                                             title="Strategic Planning"
                                             detials="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
                                        />

                                        <FaqLanding
                                             extraClasses=""
                                             title="Advance Reporting"
                                             detials="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
                                        />
                                        <FaqLanding
                                             extraClasses=""
                                             title="Business Consulting"
                                             detials="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
                                        />
                                        <FaqLanding
                                             extraClasses=""
                                             title="Automation System"
                                             detials="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </section> */}
          </Fragment>
     );
};

export default AboutUs;
