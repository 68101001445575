import React, { Fragment, FC } from "react";
import classes from "./Footer.module.css";
import { FaFacebookSquare, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
     const Services = [
          {
               label: "GST Billing Software",
               path: "#",
               id: 1,
          },
          {
               id: 2,
               label: "Pharmacy Software",
               path: "#",
          },

          {
               id: 3,
               label: "Pharma Software ",
               path: "#",
          },
          {
               id: 4,
               label: "AccountsPro Software ",
               path: "#",
          },
          {
               id: 5,
               label: "Export Billing Software",
               path: "#",
          },
          {
               id: 6,
               label: "Margerp Billing Software",
               path: "#",
          },

          {
               id: 7,
               label: "Quick Heal Antivirus",
               path: "#",
          },
          {
               id: 8,
               label: "FMCG Software",
               path: "#",
          },

          {
               id: 9,
               label: "Mobile Billing Software",
               path: "#",
          },

          {
               id: 10,
               label: "Garment Billing Software",
               path: "#",
          },

          {
               id: 11,
               label: "Footwear Billing Software",
               path: "#",
          },

          {
               id: 12,
               label: "kirana Grocery Billing Software",
               path: "#",
          },
          {
               id: 13,
               label: "Supermarket Software",
               path: "#",
          },

          {
               id: 14,
               label: "Retail Software",
               path: "#",
          },

          {
               id: 15,
               label: "Automobile and Spare parts Software",
               path: "#",
          },
          {
               id: 16,
               label: "Ayurvedic and homoeopathic Software",
               path: "#",
          },

          {
               id: 17,
               label: "Agriculture krushi software",
               path: "#",
          },

          {
               id: 18,
               label: "Customize ERP Solution Software",
               path: "#",
          },
          {
               label: "Custom Software",
               path: "#",
               id: 19,
          },
          {
               label: "Plywood software",
               path: "#",
               id: 20,
          },
          {
               label: "Plywood software",
               path: "#",
               id: 20,
          },
          {
               label: "tiles and Glass software ",
               path: "#",
               id: 21,
          },
     ];
     return (
          <Fragment>
               {/* <section className={classes.request_demo}>
          <p className={classes.request_demo_text}>{Data["footerParagraph"][0]}
          </p>
          <Link to="/contact-us">
          <Button title= {Data["footerParagraph"][1]} /></Link>
        </section> */}

               <div className="containerFluid">
                    <div className="container">
                         <footer className="pt-5">
                              <div className="row ">
                                   <div className="col-lg-5 col-md-12 col-sm-6 mb-3 ">
                                        {/* <img src={footerLogo} style={{ height: "30px" }} /> */}
                                        <h6 className="text-landing-2nd pull-left text-primary">Swastik Enterprises</h6>
                                        <div className="" style={{ marginTop: "40px" }}>
                                             {/* <p style={{ color: "#6C6C6C" }}>
                                                  Lorem ipsum dolor sit amet, consectetur <br></br>adipiscing elit eta
                                                  dolore magna.
                                             </p> */}
                                             <p style={{ color: "#6C6C6C" }}>Empowering Your Digital Future</p>
                                        </div>

                                        <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                             <ul
                                                  className={classes.footer_ul}
                                                  style={{ marginTop: "15px", display: "flex" }}
                                             >
                                                  <li className="footerlihover" style={{ marginTop: "10px" }}>
                                                       <span>
                                                            <FaFacebookSquare
                                                                 style={{ fontSize: "40px", color: "#6C6C6C" }}
                                                            />{" "}
                                                       </span>
                                                       {/* <span><FaLinkedin style={{marginLeft:"10px",fontSize:"40px"}} /></span>
                 <span><FaInstagramSquare style={{marginLeft:"10px",fontSize:"40px"}} /></span> */}
                                                  </li>
                                                  <li className="footerlihover" style={{ marginTop: "10px" }}>
                                                       <span>
                                                            <FaLinkedin
                                                                 style={{
                                                                      marginLeft: "30px",
                                                                      fontSize: "40px",
                                                                      color: "#6C6C6C",
                                                                 }}
                                                            />
                                                       </span>
                                                  </li>
                                                  <li className="footerlihover" style={{ marginTop: "10px" }}>
                                                       <span>
                                                            <FaInstagramSquare
                                                                 style={{
                                                                      marginLeft: "30px",
                                                                      fontSize: "40px",
                                                                      color: "#6C6C6C",
                                                                 }}
                                                            />
                                                       </span>
                                                  </li>
                                             </ul>
                                        </div>
                                   </div>
                                   {/* <div className="col-lg-1 col-md-0 mb-3 col-sm-6">

              </div> */}
                                   <div className="col-lg-2 col-md-6 mb-3 col-sm-6 ">
                                        <h3
                                             style={{
                                                  color: "#121212",
                                                  paddingBottom: "15px",
                                                  marginTop: "60px",
                                                  font: "600 14px / 25px Sans-serif",
                                             }}
                                        >
                                             SERVICES
                                        </h3>
                                        <div className="flex">
                                             <ul className={`${classes.footer_ul} flex`}>
                                                  {Services.map(({ id, label, path }) => (
                                                       <li key={id}>
                                                            <Link to={path}>{label}</Link>
                                                       </li>
                                                  )).slice(0, 11)}
                                             </ul>
                                        </div>
                                   </div>

                                   <div className="col-lg-2 col-md-6 mb-3 col-sm-6 ">
                                        <h3
                                             style={{
                                                  color: "#121212",
                                                  paddingBottom: "15px",
                                                  marginTop: "60px",
                                                  font: "600 14px / 25px Sans-serif",
                                             }}
                                        >
                                             MORE SERVICES
                                        </h3>
                                        <ul className={classes.footer_ul}>
                                             <ul className={classes.footer_ul}>
                                                  {Services.map(({ id, label, path }) => (
                                                       <li key={id}>
                                                            <Link to={path}>{label}</Link>
                                                       </li>
                                                  )).slice(11, 20)}
                                             </ul>
                                        </ul>
                                   </div>

                                   <div className="col-lg-3 col-md-6 mb-3 col-sm-6 ">
                                        <h3
                                             style={{
                                                  color: "#121212",
                                                  paddingBottom: "15px",
                                                  marginTop: "60px",
                                                  font: "600 14px / 25px Sans-serif",
                                             }}
                                        >
                                             LINKS
                                        </h3>
                                        <ul className={classes.footer_ul}>
                                             <li>
                                                  <Link to="#">About us</Link>
                                             </li>
                                             <li>
                                                  <Link to="#">Contact us</Link>
                                             </li>

                                             <li>
                                                  <Link to="#">Products</Link>
                                             </li>
                                        </ul>
                                   </div>
                                   {/* <div className="col-lg-1 col-md-6 mb-3 col-sm-0">

              </div> */}
                              </div>

                              <div className="copyright">
                                   <div className="row">
                                        <div className="col-lg-6">
                                             <p style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
                                                  <span style={{ color: "#6C6C6C" }}>Powered by</span>{" "}
                                                  <span>Swastik Associates.</span>
                                             </p>
                                        </div>
                                        <div className="col-lg-6">
                                             <p className="copyrightP">Privacy Policy | Our Terms</p>
                                        </div>
                                   </div>
                              </div>
                         </footer>
                    </div>
               </div>
          </Fragment>
     );
};

export default Footer;
