import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { useSelector } from "react-redux";
import { Services } from "../../redux/data/services";

export interface ServiceProps {
     image?: string;
     id: number;
     title: string;
     desc: string;
     keyHigh: { title: string; desc: string }[];
     features: {
          title: string;
          subTitle?: string;
          desc?: string;
     }[];
     path: string;
     category: "retail" | "distribution" | "manufacturing";
}

export interface ServiceSliceProps {
     services: ServiceProps[];
     currentService: ServiceProps;
}

const initialState: ServiceSliceProps = {
     services: Services,
     currentService: {} as ServiceProps,
};

const ServicesSlice = createSlice({
     initialState,
     name: "services",
     reducers: {
          setCurrentService: (state, action: PayloadAction<number>) => {
               state.services.filter((element) => {
                    if (element.id === action.payload) {
                         state.currentService = { ...element };
                         return element;
                    } else {
                         return null;
                    }
               });
          },
     },
});

export const ServicesReducer = ServicesSlice.reducer;
export const useServiceSlice = () =>
     useSelector((state: RootState) => {
          return state.services;
     });
export const { setCurrentService } = ServicesSlice.actions;
