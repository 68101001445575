import React, { useState } from 'react';
import classes from './FaqLanding.module.css';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

interface FaqLandingProps {
  title: string;
  detials: string;
  extraClasses?: string;
}

const FaqLanding: React.FC<FaqLandingProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${classes.faq_div} ${props.extraClasses}`} onClick={showAnswer}>
      <h3 className={`p-relative mb-3 faqHeder${isOpen ? ' fqaBG' : ''}`}>
        {props.title} <span className={classes.faq_icon}>{isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</span>
      </h3>
      <p className={`iconPeras ${isOpen ? '' : 'd-none'}`}>{props.detials}</p>
    </div>
  );
};

export default FaqLanding;
