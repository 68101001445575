import React, { Fragment, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Services from "./pages/Services";
import Feature from "./pages/Feature";
import { Navbar } from "./components/navbar/Navbar";
import Post from "./pages/Post";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Page404 from "./pages/404";
import SingleService from "./pages/SingleService";
import HomePage from "./pages/HomePage";
import Process from "./pages/Process";
import About from "./pages/AboutUs";
import Pricing from "./pages/Pricing";
import { ServiceDetailsPage } from "pages/service";

const App: React.FC = () => {
     const location = useLocation();
     // Scroll to top if path changes
     useLayoutEffect(() => {
          window.scrollTo(0, 0);
     }, [location.pathname]);

     return (
          <Fragment>
               {location.pathname === "/Landing" ? <Navbar /> : <Navbar />}
               <Routes>
                    <Route path="/feature" element={<Feature />} />
                    <Route path="/services" element={<Services />} />
                    {/* // <Route path="/post" element={<Post />} />
                    // <Route path="/blogs" element={<Blog />} /> */}
                    <Route path="/contact-us" element={<ContactUs />} />
                    {/* <Route path="/singleService" element={<SingleService />} /> */}
                    <Route path="/about" element={<About />} />
                    {/* <Route path="/process" element={<Process />} /> */}
                    {/* <Route path="/pricing" element={<Pricing />} /> */}
                    <Route path="/" element={<HomePage />} />
                    <Route path="/services/:id" element={<ServiceDetailsPage />} />
                    <Route path="/*" element={<Page404 />} />
               </Routes>
               {location.pathname === "/" ? <Footer /> : <Footer />}
          </Fragment>
     );
};

export default App;
