import React, { Fragment, useState } from "react";
import classes from "./Navbar.module.css";
import { BsFillGridFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import { ServiceProps, useServiceSlice } from "../../redux/features";

export const Navbar = () => {
     const { services } = useServiceSlice();
     const [HamburgerIsOpen, SetHamburgerIsOpen] = useState(false);
     const [showProductDropDown, setShowProductDropDown] = useState(false);
     const [showResourcesDropDown, setShowResourcesDropDown] = useState(false);
     const [showComDropDown, setShowComDropDown] = useState(false);
     const navigate = useNavigate();

     const hamburgerClickHandler = () => {
          const mobileDiv = document.getElementById("navbar__mobile__div");

          if (mobileDiv) {
               if (!HamburgerIsOpen) {
                    mobileDiv.classList.add("slide_from_top");
                    mobileDiv.classList.remove("slide-from-bottom");
               } else {
                    mobileDiv.classList.add("slide-from-bottom");
                    mobileDiv.classList.remove("slide_from_top");
               }
               SetHamburgerIsOpen(!HamburgerIsOpen);
          }
     };

     // const toggleProductDropdown = (e) => {

     const toggleComDropdown = (e: React.MouseEvent<HTMLAnchorElement>) => {
          // const toggleComDropdown = (e) => {
          e.preventDefault();
          setShowComDropDown(!showComDropDown);
          setShowResourcesDropDown(false);
          setShowProductDropDown(false);
     };

     const toggleProductDropdown = (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          setShowProductDropDown(!showProductDropDown);
          setShowResourcesDropDown(false);
          setShowComDropDown(false);
     };
     return (
          <div className={classes.navbar__div}>
               <h4 className="text-primary text-decoration-none">Swastik Associates</h4>

               <div className={classes.navbar__div__links}>
                    <ul className={classes.navbar__div__links_ul}>
                         <li className={classes.navbar__div__link}>
                              <Link to="/">Home</Link>
                         </li>
                         {/* <li className={classes.navbar__div__link}>
                              <Link
                                   to="/services"
                                   onMouseOver={toggleComDropdown}
                                   className={classes.nav_barlink_dropdown}
                              >
                                   Services
                              </Link>
                         </li> */}

                         <li className={classes.navbar__div__link + " " + classes.navbar__div__link__has_dropdown}>
                              <Link to="#" className={classes.nav_barlink_dropdown} onMouseOver={toggleProductDropdown}>
                                   Product <RiArrowDownSLine />
                              </Link>
                              {showProductDropDown && (
                                   <Fragment>
                                        <div
                                             className={classes.navbar_backdrop}
                                             onMouseOver={() => {
                                                  setShowProductDropDown(!showProductDropDown);
                                             }}
                                        ></div>
                                        <div
                                             className={
                                                  classes.productDropdown + "  " + classes.borderRadiusNavbarDropdown
                                             }
                                        >
                                             <div className="">
                                                  <div className="">
                                                       <div
                                                            style={{
                                                                 display: "flex",
                                                                 flexDirection: "row",
                                                            }}
                                                       >
                                                            <div className={classes.product_text}>
                                                                 <ul
                                                                      style={{
                                                                           listStyle: "none",
                                                                      }}
                                                                 >
                                                                      <h6 className="text-primary font-size-20 fw-semibold text-uppercase ml-1">
                                                                           Retail Software
                                                                      </h6>
                                                                      {services
                                                                           .filter((element: ServiceProps) => {
                                                                                if (element.category === "retail") {
                                                                                     return element;
                                                                                } else {
                                                                                     return null;
                                                                                }
                                                                           })
                                                                           .map(({ id, title, path }: ServiceProps) => (
                                                                                <li
                                                                                     key={id}
                                                                                     style={{
                                                                                          marginTop: "5px",
                                                                                          marginLeft: "5px",
                                                                                     }}
                                                                                >
                                                                                     <Link
                                                                                          to={`/services/${path}`}
                                                                                          onClick={() => {
                                                                                               setShowProductDropDown(
                                                                                                    !showProductDropDown
                                                                                               );
                                                                                          }}
                                                                                     >
                                                                                          {title}
                                                                                     </Link>
                                                                                </li>
                                                                           ))}
                                                                 </ul>
                                                            </div>
                                                            <div className={classes.product_text}>
                                                                 <ul
                                                                      style={{
                                                                           listStyle: "none",
                                                                      }}
                                                                 >
                                                                      <h6 className="text-primary font-size-20 fw-semibold text-uppercase ml-1">
                                                                           distribution Software
                                                                      </h6>
                                                                      {services
                                                                           .filter((element: ServiceProps) => {
                                                                                if (
                                                                                     element.category === "distribution"
                                                                                ) {
                                                                                     return element;
                                                                                } else {
                                                                                     return null;
                                                                                }
                                                                           })
                                                                           .map(({ id, title, path }: ServiceProps) => (
                                                                                <li
                                                                                     key={id}
                                                                                     style={{
                                                                                          marginTop: "5px",
                                                                                          marginLeft: "5px",
                                                                                     }}
                                                                                >
                                                                                     <Link
                                                                                          to={`/services/${path}`}
                                                                                          onClick={() => {
                                                                                               setShowProductDropDown(
                                                                                                    !showProductDropDown
                                                                                               );
                                                                                          }}
                                                                                     >
                                                                                          {title}
                                                                                     </Link>
                                                                                </li>
                                                                           ))}
                                                                 </ul>
                                                            </div>
                                                            <div className={classes.product_text}>
                                                                 <ul
                                                                      style={{
                                                                           listStyle: "none",
                                                                      }}
                                                                 >
                                                                      <h6 className="text-primary font-size-20 fw-semibold text-uppercase ml-1">
                                                                           manufacturing Software
                                                                      </h6>
                                                                      {services
                                                                           .filter((element: ServiceProps) => {
                                                                                if (
                                                                                     element.category ===
                                                                                     "manufacturing"
                                                                                ) {
                                                                                     return element;
                                                                                } else {
                                                                                     return null;
                                                                                }
                                                                           })
                                                                           .map(({ id, title, path }: ServiceProps) => (
                                                                                <li
                                                                                     key={id}
                                                                                     style={{
                                                                                          marginTop: "5px",
                                                                                          marginLeft: "5px",
                                                                                     }}
                                                                                >
                                                                                     <Link
                                                                                          to={`/services/${path}`}
                                                                                          onClick={() => {
                                                                                               setShowProductDropDown(
                                                                                                    !showProductDropDown
                                                                                               );
                                                                                          }}
                                                                                     >
                                                                                          {title}
                                                                                     </Link>
                                                                                </li>
                                                                           ))}
                                                                 </ul>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </Fragment>
                              )}
                         </li>

                         <li className={classes.navbar__div__link}>
                              <Link
                                   to="/services"
                                   onMouseOver={toggleComDropdown}
                                   className={classes.nav_barlink_dropdown}
                              >
                                   Services
                              </Link>
                         </li>
                         <li className={classes.navbar__div__link}>
                              <Link
                                   to="/About"
                                   onClick={hamburgerClickHandler}
                                   className={classes.nav_barlink_dropdown}
                              >
                                   About
                              </Link>
                         </li>
                    </ul>
                    <div className={classes.navbar__div__links_buttons}>
                         <button onClick={() => navigate("/contact-us")} className="contactBtn">
                              Contact Us
                         </button>
                    </div>
               </div>

               {!HamburgerIsOpen ? (
                    <BsFillGridFill onClick={hamburgerClickHandler} className={classes.navbar__hamburger} />
               ) : (
                    <AiOutlineClose onClick={hamburgerClickHandler} className={classes.navbar__hamburger} />
               )}

               <div id="navbar__mobile__div" className={classes.navbar__mobile}>
                    <ul>
                         <li className={classes.navbar__div__link + " " + classes.navbar__div__link__has_dropdown}>
                              <Link to="/" className={classes.nav_barlink_dropdown} onClick={hamburgerClickHandler}>
                                   Home
                              </Link>
                         </li>

                         <li className={classes.navbar__div__link + " " + classes.navbar__div__link__has_dropdown}>
                              <Link to="#" className={classes.nav_barlink_dropdown} onMouseOver={toggleProductDropdown}>
                                   Product <RiArrowDownSLine />
                              </Link>
                              {showProductDropDown && (
                                   <Fragment>
                                        <div
                                             className={classes.navbar_backdrop}
                                             onMouseOver={() => {
                                                  setShowProductDropDown(!showProductDropDown);
                                             }}
                                        ></div>
                                        <div
                                             className={
                                                  classes.productDropdown + "  " + classes.borderRadiusNavbarDropdown
                                             }
                                        >
                                             <div className="">
                                                  <div className="">
                                                       <div
                                                            style={{
                                                                 display: "flex",
                                                                 flexDirection: "column",
                                                            }}
                                                       >
                                                            <div className={classes.product_text}>
                                                                 <ul
                                                                      style={{
                                                                           listStyle: "none",
                                                                      }}
                                                                 >
                                                                      <h6 className="text-primary font-size-20 fw-semibold text-uppercase ml-1">
                                                                           Retail Software
                                                                      </h6>
                                                                      {services
                                                                           .filter((element: ServiceProps) => {
                                                                                if (element.category === "retail") {
                                                                                     return element;
                                                                                } else {
                                                                                     return null;
                                                                                }
                                                                           })
                                                                           .map(({ id, title, path }: ServiceProps) => (
                                                                                <li
                                                                                     key={id}
                                                                                     style={{
                                                                                          marginTop: "5px",
                                                                                          marginLeft: "5px",
                                                                                     }}
                                                                                >
                                                                                     <Link
                                                                                          to={`/services/${path}`}
                                                                                          onClick={() => {
                                                                                               setShowProductDropDown(
                                                                                                    !showProductDropDown
                                                                                               );
                                                                                          }}
                                                                                     >
                                                                                          {title}
                                                                                     </Link>
                                                                                </li>
                                                                           ))}
                                                                 </ul>
                                                            </div>
                                                            <div className={classes.product_text}>
                                                                 <ul
                                                                      style={{
                                                                           listStyle: "none",
                                                                      }}
                                                                 >
                                                                      <h6 className="text-primary font-size-20 fw-semibold text-uppercase ml-1">
                                                                           distribution Software
                                                                      </h6>
                                                                      {services
                                                                           .filter((element: ServiceProps) => {
                                                                                if (
                                                                                     element.category === "distribution"
                                                                                ) {
                                                                                     return element;
                                                                                } else {
                                                                                     return null;
                                                                                }
                                                                           })
                                                                           .map(({ id, title, path }: ServiceProps) => (
                                                                                <li
                                                                                     key={id}
                                                                                     style={{
                                                                                          marginTop: "5px",
                                                                                          marginLeft: "5px",
                                                                                     }}
                                                                                >
                                                                                     <Link
                                                                                          to={`/services/${path}`}
                                                                                          onClick={() => {
                                                                                               setShowProductDropDown(
                                                                                                    !showProductDropDown
                                                                                               );
                                                                                          }}
                                                                                     >
                                                                                          {title}
                                                                                     </Link>
                                                                                </li>
                                                                           ))}
                                                                 </ul>
                                                            </div>
                                                            <div className={classes.product_text}>
                                                                 <ul
                                                                      style={{
                                                                           listStyle: "none",
                                                                      }}
                                                                 >
                                                                      <h6 className="text-primary font-size-20 fw-semibold text-uppercase ml-1">
                                                                           manufacturing Software
                                                                      </h6>
                                                                      {services
                                                                           .filter((element: ServiceProps) => {
                                                                                if (
                                                                                     element.category ===
                                                                                     "manufacturing"
                                                                                ) {
                                                                                     return element;
                                                                                } else {
                                                                                     return null;
                                                                                }
                                                                           })
                                                                           .map(({ id, title, path }: ServiceProps) => (
                                                                                <li
                                                                                     key={id}
                                                                                     style={{
                                                                                          marginTop: "5px",
                                                                                          marginLeft: "5px",
                                                                                     }}
                                                                                >
                                                                                     <Link
                                                                                          to={`/services/${path}`}
                                                                                          onClick={() => {
                                                                                               setShowProductDropDown(
                                                                                                    !showProductDropDown
                                                                                               );
                                                                                          }}
                                                                                     >
                                                                                          {title}
                                                                                     </Link>
                                                                                </li>
                                                                           ))}
                                                                 </ul>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </Fragment>
                              )}
                         </li>
                         <li className={classes.navbar__div__link}>
                              <Link
                                   to="/services"
                                   onClick={hamburgerClickHandler}
                                   className={classes.nav_barlink_dropdown}
                              >
                                   Service
                              </Link>
                         </li>
                        
                    </ul>
                    <div className={classes.navbar__div__links_buttons}></div>
               </div>
          </div>
     );
};
