import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { setCurrentService, useServiceSlice } from "../../../redux/features";
import { useAppDispatch } from "../../../redux";
import FaqLanding from "components/faq_landing/FaqLanding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareRss } from "@fortawesome/free-solid-svg-icons";
import Mission from "../../../assets/hyb20.jpg";

export const ServiceDetailsPage = () => {
     const { id } = useParams();
     const { currentService } = useServiceSlice();
     const dispatch = useAppDispatch();
     useEffect(() => {
          dispatch(setCurrentService(parseInt(id as string)));
     }, [dispatch, id]);
     console.log(currentService);
     return (
          <Fragment>
               {/* banner */}
               <section>
                    <div className="container-fluid" style={{ backgroundColor: "#FAFAFA" }}>
                         <div className="container">
                              <div
                                   className="row align-items-center rowPading fadeInUp"
                                   style={{ padding: "0px 0px 0px 0px" }}
                              >
                                   <div className="col-lg-7 col-md-6 col-sm-12">
                                        <div>
                                             <h1 className="techHader text-primary">{currentService.title}</h1>
                                        </div>
                                   </div>
                                   <div className="col-lg-5 col-md-6 col-sm-12" style={{ marginTop: "60px" }}>
                                        <h6 className="homeHeader">
                                             HOME &nbsp; &nbsp; &gt; &nbsp; &nbsp; PRODUCT &nbsp; &nbsp; &gt; &nbsp;
                                             &nbsp; {currentService.title}
                                        </h6>
                                        <p className="homePera">
                                             {/* Join us as we explore the ever-evolving landscape of technology and
                                             discover how it can transform your life. */}
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               {/* banner */}
               <div className="container">
                    <div className="row pview fadeInUp" style={{ padding: "30px 0px 30px 0px" }}>
                         <div className="col-lg-6 col-md-12 col-sm-12 fadeInUp">
                              <div
                                   className="card  imgHeght"
                                   style={{
                                        backgroundImage: `url(${
                                             currentService.image ? currentService.image : Mission
                                        })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        // height: '400px',
                                        border: "none",
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "30px 30px 30px 30px",
                                        // margin: "0px 0px 0px 60px",
                                        padding: "30px 30px 30px 30px",
                                   }}
                              >
                                   {/* <div className="outterDiv">
                                        <div className="">
                                             <div className="elementorwidget">
                                                  <div className="elementoricon">
                                                       <span className="">
                                                            <span className="aiImg aiInsise">
                                                                 <FontAwesomeIcon
                                                                      icon={faUserShield}
                                                                      className="aiIcon aiinside"
                                                                 />
                                                            </span>
                                                       </span>
                                                  </div>
                                                  <div className="elementor-icon-box-content">
                                                       <h4 className="elementorIcon">
                                                            <span>{currentService.title}</span>
                                                       </h4>
                                                       <p className="iconInside">{currentService.desc}</p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div> */}
                              </div>
                         </div>
                         <div
                              className="col-lg-6 col-md-12 col-sm-12 pview fadeInUp"
                              style={{ padding: "0px 0px 0px 20px" }}
                         >
                              <span className="servicesIcons">FEATURES</span>
                              <h3 className="servicesPara">{currentService.desc}</h3>
                              {currentService.keyHigh?.map(({ desc, title }: { title: string; desc: string }) => (
                                   <FaqLanding extraClasses="" title={title} detials={desc} />
                              ))}
                         </div>
                    </div>
               </div>
               <section>
                    <div className="container-fluid" style={{ padding: "20px 0px 20px 0px" }}>
                         <div className="fadeInUp">
                              <div className="row fadeInUp">
                                   <div className="text-center" style={{ marginBottom: "70px" }}>
                                        <span className="servicesIcons">KEY HIGHLIGHTS</span>
                                        <h2 className="servicesPara">
                                             {/* Optimize Your <span style={{ color: "#4D52D9" }}>Technology</span>{" "}
                                             Performance */}
                                             Tailored Solutions, Your{" "}
                                             <span style={{ color: "#4D52D9" }}>Infinite Possibilities</span>
                                        </h2>
                                        <p className="iconPera rowPading" style={{ padding: "0% 20% 0% 20%" }}>
                                             Explore Our Range of Software Services Designed to Elevate Your Business
                                        </p>
                                   </div>
                                   <div className="row fadeInUp mx-auto justify-content-center gap-lg-5">
                                        {currentService?.features?.map(
                                             ({ desc, title }: { desc: string; title: string }) => (
                                                  <div className="col-lg-3 col-md-6 col-sm-12">
                                                       <div className="servicesIcon">
                                                            <FontAwesomeIcon
                                                                 icon={faSquareRss}
                                                                 className="innovationIcon"
                                                            />
                                                       </div>
                                                       <h3 className="mb-3 mt-4 iconHeder">{title}</h3>
                                                       <p className="iconPera">{desc}</p>
                                                  </div>
                                             )
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </Fragment>
     );
};
